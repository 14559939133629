/* global alert prompt */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AuthService from '../../service/authService/authService';
import { generatePWResetMail } from '../../service/transport/user';

import { BasicFlexContainer, Page } from '../../components/styled/containers';
import { PrimaryHeader } from '../../components/styled/headers';
import { PillButton } from '../../components/styled/buttons';
import { TextInput, InputLabel } from '../../components/styled/inputs';
import FlexForm from '../../components/styled/FlexForm';
import BaronaFooter from '../../components/BaronaFooter/BaronaFooter';
import testIfInternetExplorer from '../../service/authService/ieTester';
import BackgroundImage from '../../assets/BackgroundRocket.jpg';

class Login extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService(process.env.REACT_APP_BACKEND_URL);
  }

  state = {
    password: '',
    username: '',
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    testIfInternetExplorer();
    const { history } = this.props;
    if (this.Auth.loggedIn()) history.replace('/');
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  handleFormSubmit(e) {
    const { username, password } = this.state;
    const { history } = this.props;

    e.preventDefault();
    const { logIn } = this.Auth;
    logIn(username, password)
      .then(() => {
        history.replace('/tuh');
      })
      .catch((err) => {
        alert('Kirjautuminen epäonnistui. Tarkista käyttäjätunnus ja salasana.');
      });
  }

  resetPassword = () => {
    const email = prompt('Syötä sähköpostiosoitteesi alla olevaan kenttään niin saat sähköpostitse ohjeet käyttäjätunnuksen palauttamiseksi. ');
    if (!email) return;

    generatePWResetMail({ email }).then((response) => {
      if (response.error) {
        alert('Tapahtui virhe. Ole ystävällinen ja yritä uudelleen!');
      } else {
        alert('Antamaasi sähköpostiosoitteseen on lähetetty ohjeet salasanan muuttamiseksi, mikäli osoite on rekisteröity palveluun.');
      }
    });
  }

  resetPasswordClickKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.resetPassword();
    }
  }

  render() {
    return (
      <Page>
        <BasicFlexContainer direction="row" style={{ alignItems: 'center', justifyContent: 'space-around' }}>
          <BasicFlexContainer
            direction="column"
            className="mobileBackground"
            style={{
              flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <PrimaryHeader style={{ textAlign: 'center', marginBottom: 20 }}>
              Kirjaudu
              <br />
              Työurahuoltoon
            </PrimaryHeader>
            <FlexForm onSubmit={this.handleFormSubmit}>
              <InputLabel htmlFor="username">Käyttäjätunnus</InputLabel>
              <TextInput
                name="username"
                type="text"
                placeholder="Sähköposti"
                onChange={this.handleChange}
              />
              <InputLabel htmlFor="password">Salasana</InputLabel>
              <TextInput
                name="password"
                type="password"
                onChange={this.handleChange}
              />
              <BasicFlexContainer style={{ paddingTop: '2rem', paddingLeft: '1.6rem' }}>
                <PillButton style={{ color: '#2c2c2c' }}>Kirjaudu sisään</PillButton>
              </BasicFlexContainer>
              <BasicFlexContainer direction="column" style={{ flexDirection: 'column', paddingTop: '2rem' }}>
                <span>
                  <Link to="/register" style={{ color: 'white', textDecoration: 'none' }}> Luo uusi käyttäjätunnus?</Link>
                </span>
                <span
                  role="button"
                  tabIndex={0}
                  onClick={this.resetPassword}
                  onKeyDown={this.resetPasswordClickKeyDown}
                  style={{ cursor: 'pointer' }}
                >
                  Salasana unohtunut?
                </span>
              </BasicFlexContainer>
            </FlexForm>
          </BasicFlexContainer>
        </BasicFlexContainer>
        <BaronaFooter position="fixed" />
      </Page>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default Login;
