/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  InlineFlexContainer, Page, TopBar,
} from '../../components/styled/containers';
import { RectangleButton } from '../../components/styled/buttons';
import withAuthContext from '../../contexts/withAuthContext';
import ControlPanel from '../../components/admin/controlPanel/ControlPanel';
import Keycodes from '../../components/admin/keycodes/Keycodes';
import Users from '../../components/admin/users/Users';
import { SecondaryHeader } from '../../components/styled/headers';

const CONTROL_PANEL_TAB = '0'; const KEYCODES_TAB = '1'; const
  USERS_TAB = '2';

class Admin extends Component {
  state = {
    activeTab: this.props.getTab(), // eslint-disable-line
  };

  changeTab = ({ target: { value } }) => {
    const { setTab } = this.props;
    setTab(value);
    this.setState({ activeTab: value });
  }

  render() {
    const { activeTab } = this.state;
    const { handleLogOut } = this.props;
    return (
      <Page style={{ backgroundColor: '#ffffff' }}>
        <TopBar>
          <InlineFlexContainer style={{ width: '100vw', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
            <SecondaryHeader style={{ marginLeft: 15, marginBottom: 18 }}>
              Työurahuolto
            </SecondaryHeader>
            <InlineFlexContainer>
              <RectangleButton value="0" active={activeTab === CONTROL_PANEL_TAB} onClick={this.changeTab}>Hallintapaneeli</RectangleButton>
              <RectangleButton value="1" active={activeTab === KEYCODES_TAB} onClick={this.changeTab}>Lisää avainkoodi</RectangleButton>
              <RectangleButton value="2" active={activeTab === USERS_TAB} onClick={this.changeTab}>Käyttäjät</RectangleButton>
              <RectangleButton onClick={handleLogOut}>Kirjaudu ulos</RectangleButton>
            </InlineFlexContainer>
          </InlineFlexContainer>
        </TopBar>
        {activeTab === '0' ? <ControlPanel user={this.props.user} /> : activeTab === '1' ? <Keycodes /> : activeTab === '2' ? <Users /> : <ControlPanel user={this.props.user} />}
      </Page>
    );
  }
}

Admin.propTypes = {
  handleLogOut: propTypes.func.isRequired,
  setTab: propTypes.func.isRequired,
};

export default withAuthContext(Admin);
