import styled from 'styled-components';

export const PrimaryHeader = styled.h1`
  font-family: 'Inter-Bold';
  font-size: 2.80rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  margin-bottom: 0;
  letter-spacing: .2rem;
  color: ${props => props.color || '#ffffff'};

  @media (max-width: 800px) {
    font-size: 1.7rem;
  }

  @media (min-width: 1600px) {
    font-size: 4.0rem;
  }
`;

export const SecondaryHeader = styled.span`
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.07rem;
  color: ${props => props.color || '#ffffff'};
`;

export const ModalFormHeader = styled.span`
  padding-bottom: 1rem;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  color: ${props => props.color || '#ffffff'};
`;

export const CheckboxLabel = styled.label`
  font-size: 1.0rem;
  color: #ffffff;
  padding: 10px 0;
`;
