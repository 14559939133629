import React, { Component } from 'react';
import Table from '../../Table/Table';
import classes from './ControlPanel.module.css';
import { PillButton } from '../../styled/buttons';
import expanderIcon from '../../../assets/nuoli.svg';
import { getCases, deleteCase } from '../../../service/transport/admin';
import { generateTuhReport } from '../../../service/utils';

const columns = [
  {
    id: 'userName',
    Header: 'Lähettäjä',
    accessor: ({ user }) => user && `${user.firstName} ${user.lastName}`,
  },
  {
    id: 'personName',
    Header: 'Ohjattu työntekijä',
    accessor: ({ lastName, firstName }) => `${firstName} ${lastName}`,
  },
  {
    id: 'phoneNumber',
    Header: 'Puhelin',
    accessor: 'phone',
  },
  {
    id: 'email',
    Header: 'Sähköposti',
    accessor: 'email',
  },
  {
    id: 'customerName',
    Header: 'Yritys',
    accessor: 'customerName',
  },
  {
    id: 'location',
    Header: 'Paikkakunta',
    accessor: 'location',
  },
  {
    id: 'createdAt',
    Header: 'Lomake lähetetty',
    accessor: 'createdAt',
    Cell: ({ row }) => {
      const date = new Date(row.values.createdAt);
      return <span className="string">{`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</span>; // Custom cell components!
    },
  },
  {
    id: 'employeefeedback.createdAt',
    Header: 'Lomake avattu',
    accessor: 'employeefeedback.createdAt',
    Cell: ({ row }) => {
      if (row.values['employeefeedback.createdAt']) {
        const date = new Date(row.values['employeefeedback.createdAt']);
        return <span className="string">{`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</span>; // Custom cell components!
      }
      return <span style={{ color: 'red' }}>Ei avattu</span>;
    },
  },
  {
    id: 'formSaved',
    Header: 'Lomake tallennettu',
    accessor: ({ employeefeedback, tokenHash }) => {
      if (employeefeedback && !tokenHash) {
        return employeefeedback.updatedAt;
      }
      return '';
    },
    Cell: ({ row }) => {
      if (row.values.formSaved) {
        const date = new Date(row.values.formSaved);
        return <span className="string">{`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</span>; // Custom cell components!
      }
      return <span style={{ color: 'red' }}>Ei tallennettu</span>;
    },
  },
  {
    id: 'deleteCase',
    Header: '',
    Cell: '', // Note that this is dynamically set before "columns" is passed to "Table" below.
    caseId: ({ row }) => row.original.id,
  },
];

const subComponent = ({ row }) => {
  if (row.original.commissiondetails) {
    const {
      original: {
        commissiondetails: {
          employerReasons, employeeReasons, employeeOwnWords, employerOwnWords,
        },
        latestCommission,
        user: {
          firstName,
          lastName,
          phone,
        },
      },
    } = row;
    return (
      <div style={{ color: 'black' }}>
        {latestCommission
        && (
          <div style={{ padding: '1rem 0 0.5rem 2rem', display: 'flex' }}>
            <b>Henkilö on ohjattu Tykyyn.&nbsp;</b>
            <span>
              {`- Viimeisin Toimenpide Tykyssä: ${latestCommission}`}
            </span>
          </div>
        )
        }
        {firstName && lastName && (
          <div style={{ padding: '1rem 0 0.5rem 2rem', display: 'flex' }}>
            <b>Tilaaja:&nbsp;</b>
            <span>{`${firstName} ${lastName}${phone ? `, puh. ${phone}` : ''}`}</span>
          </div>
        )}
        {employerReasons && employerReasons.length > 0
      && (
      <div style={{ padding: '1rem 0 1rem 2rem', display: 'flex', flexDirection: 'column' }}>
        <b>Ohjautumisen syyt (tilaaja)</b>
        { employerReasons.map(r => (
          <span>
            {`- ${r}`}
          </span>
        ))}
        {employerOwnWords && (
        <span style={{ paddingTop: '.5rem' }}>
          <b>Tilaajan omin sanoin: </b>
          {employerOwnWords}
        </span>
        ) }
      </div>
      )}
        {employeeReasons && employeeReasons.length > 0
      && (
      <div style={{ padding: '1rem 0 1rem 2rem', display: 'flex', flexDirection: 'column' }}>
        <b>Avun tarve (työntekijä)</b>
        { employeeReasons.map(r => (
          <span>
            {`- ${r}`}
          </span>
        ))}
        {employeeOwnWords && (
        <span style={{ paddingTop: '.5rem' }}>
          <b>Työntekijän omin sanoin: </b>
          {employeeOwnWords}
        </span>
        ) }
      </div>
      )}
      </div>
    );
  }
  return null;
};

class ControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
    };
  }

  componentDidMount() {
    getCases().then(cases => this.setState({ cases }));
  }

  generateReport = () => {
    generateTuhReport(this.state.cases);
  }

  render() {
    const { cases } = this.state;
    const { isAdmin } = this.props.user;
    const initialState = {
      sortBy: [{ id: 'createdAt', desc: true }],
    };

    const clickDeleteCase = async (clickEvent) => {
      if (window.confirm('Haluatko varmasti poistaa?')) {
        const { caseId } = clickEvent.target.dataset;
        const result = await deleteCase(caseId);
        if (result.success) {
          const casesToDeleteFrom = this.state.cases.slice();
          const casesDeleted = casesToDeleteFrom.filter(c => c.id.toString() !== caseId);
          this.setState({ cases: casesDeleted });
        } else {
          throw new Error(result);
        }
      }
    };

    // The delete button is added to the last cell of the columns array.
    columns[columns.length - 1].Cell = ({ row }) => <input type="button" value="Poista" data-case-id={row.original.id} onClick={clickDeleteCase} />;

    return (
      <div style={{ padding: '12rem 0', margin: '0 5rem', overflow: 'visible' }}>
        {isAdmin
          ? (
            <div style={{ padding: '1.3rem 1.5rem 1rem 0', textAlign: 'right' }}>
              <PillButton blue onClick={() => this.generateReport()}>Luo raportti</PillButton>
            </div>
          )
          : ''}
        <Table
          data={cases}
          click={() => {}}
          columns={columns}
          subComponent={subComponent}
          id="controlPanel"
          initialState={initialState}
        />
      </div>
    );
  }
}

export default ControlPanel;
