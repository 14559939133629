import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer } from '../../styled/containers';
import FlexForm from '../../styled/FlexForm';
import { TextInput, InputLabelLargeGap } from '../../styled/inputs';

const RelationsAndOwnWords = ({ handleChange, stateValue }) => (
  <BasicFlexContainer direction="column" style={{ flexDirection: 'column', marginTop: '40px' }}>
    <FlexForm>
      <InputLabelLargeGap
        error={stateValue.errors && stateValue.errors.has('roleRelation')}
      >
        Mikä on roolisi suhteessa työntekijään? *
      </InputLabelLargeGap>
      <TextInput
        style={{ width: 'auto' }}
        type="text"
        name="roleRelation"
        value={stateValue.roleRelation}
        onChange={handleChange}
      />
      <InputLabelLargeGap>Voit vielä kertoa tilanteesta omin sanoin</InputLabelLargeGap>
      <textarea
        name="inOwnWords"
        value={stateValue.inOwnWords}
        onChange={handleChange}
        style={{
          height: '100px',
          fontSize: '16px',
          marginBottom: '20px',
        }}
      />
    </FlexForm>
  </BasicFlexContainer>
);

RelationsAndOwnWords.propTypes = {
  handleChange: PropTypes.func.isRequired,
  stateValue: PropTypes.shape({}).isRequired,
};

export default RelationsAndOwnWords;
