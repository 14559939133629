/* global alert, window  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { checkToken, resetPassword } from '../../service/transport/user';
import { Page } from '../../components/styled/containers';
import PasswordReset from '../../components/PasswordReset/PasswordReset';

class PasswordResetContainer extends Component {
  state = {
    tokenValid: false,
    tokenChecked: false,
    email: '',
  }

  componentDidMount() {
    this.checkTokenFromUrl();
  }

  componentWillUnmount() {
    this.setState({ tokenValid: false, tokenChecked: false, email: '' });
  }

  checkTokenFromUrl = () => {
    const { match: { params: { token } } } = this.props;
    return checkToken(token)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log('resp', response.status);
        if (response.error) {
          throw new Error();
        }
        this.setState({
          tokenValid: true,
          tokenChecked: true,
          email: response.email,
        });
      })
      .catch(() => {
        this.setState({ tokenValid: false, tokenChecked: true, email: '' });
      });
  }

  sendNewPassword = (password) => {
    const { match: { params: { token } } } = this.props;
    const { email } = this.state;
    const body = { email, token, password };
    resetPassword(body)
      .then((response) => {
        if (response.error) {
          throw new Error();
        }
        alert('Salasana päivitetty!');
        window.location.replace('/');
      })
      .catch(() => alert('Tapahtui virhe! Ole ystävällinen ja yritä uudelleen'));
  }

  render() {
    const {
      tokenValid, tokenChecked,
    } = this.state;
    if (tokenChecked && !tokenValid) {
      return (
        <Page style={{ padding: '8rem' }}>
          <h1> Hakemaasi osoitetta ei löytynyt! </h1>
        </Page>
      );
    } if (tokenValid) {
      return (
        <PasswordReset
          sendNewPW={this.sendNewPassword}
        />
      );
    }
    return null;
  }
}

PasswordResetContainer.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default PasswordResetContainer;
