import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer, InlineFlexContainer } from '../../styled/containers';
import { CheckboxLabel } from '../../styled/headers';
import { CheckboxInput, InputLabel } from '../../styled/inputs';

const OPTIONS = [
  { text: 'Haluaisin apua työurani eteenpäin viemiseen ja tulevaisuuden suunnitteluun.', value: 'iWantHelp' },
  //  { text: 'Olen ajautunut nykyiseen työtehtävääni.
  //  Kaipaan työltäni jotain aivan muuta.', value: 'iAmDrifting' },
  { text: 'Nykyinen työni ei motivoi minua riittävästi.', value: 'noMotivation' },
  //  { text: 'Työnantajani uramahdollisuuden eivät houkuttele minua tai niitä ei ole.',
  //   value: 'notInterested' },
  //  { text: 'Oma näkemykseni työni laadusta tai tavoitteista ei vastaa esimieheni käsitystä,
  //   en aina pääse tavoitteisiini.', value: 'differentViewsOnGoals' },
  { text: 'Olen huolissani osaamisestani tai sen riittävyydestä tulevaisuudessa.', value: 'tooDifficultJob' },
  { text: 'Työ on liian kuormittavaa tai haastavaa ja tavoitteisiin ei aina pääse.', value: 'discontentWithEmployer' },
  { text: 'Esimieheni tai työyhteisö ei tue työtäni.', value: 'unSupportiveEmployer' },
  { text: 'Jokin muu tilanne, kuvaa tarkemmin avoimessa kentässä', value: 'civilLifeReasons' },
];

const PersonalPreferences = ({ handleChange, stateValue }) => (
  <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
    <BasicFlexContainer
      direction="column"
      height="20rem"
      maxWidth="35rem"
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1rem 0rem',
        color: '#FFFFFF',
      }}
    >
      <span style={{ marginBottom: '2rem' }}>Valitse sopivimmat syyt alla olevista vaihtoehdoista. Voit valita useamman syyn.</span>
      { OPTIONS.map(({ text, value }) => (
        <InlineFlexContainer key={`employeepreference_${value}`} style={{ flexWrap: 'nowrap' }}>
          <CheckboxInput name="preference" value={value} checked={stateValue[value]} onChange={handleChange} />
          <CheckboxLabel>{text}</CheckboxLabel>
        </InlineFlexContainer>
      ))
      }
      <InputLabel htmlFor="inOwnWords">Kerro vapaasti meille tilanteestasi</InputLabel>
      <textarea
        value={stateValue.inOwnWords}
        name="inOwnWords"
        onChange={handleChange}
        style={{ height: '150px', maxWidth: '60vw', fontSize: '18px' }}
      />
    </BasicFlexContainer>
  </BasicFlexContainer>
);

PersonalPreferences.propTypes = {
  handleChange: PropTypes.func.isRequired,
  stateValue: PropTypes.shape({}).isRequired,
};

export default PersonalPreferences;
