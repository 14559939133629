import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer, InlineFlexContainer } from '../../styled/containers';
import FlexForm from '../../styled/FlexForm';
import { SecondaryHeader, CheckboxLabel } from '../../styled/headers';
import { CheckboxInput } from '../../styled/inputs';

const OPTIONS = [
  { text: 'Henkilön taidot, osaaminen tai motivaatio eivät riitä nykyiseen työtehtävään', value: 'workLifeConcerns' },
  // { text: 'Olen huolissani henkilön työmotivaatiosta.', value: 'workMotivationConcerns' },
  { text: 'Työntekijän henkilökohtainen tilanne vaikuttaa työssä suoriutumiseen', value: 'lifeSituationConcerns' },
  // { text: 'Olen huolissani henkilön osaamisesta.', value: 'competenceConcerns' },
  // { text: 'Olen huolissani henkilön osaamisesta,
  // koska se ei vastaa yrityksen tulevaisuuden liiketoimintaa.',
  // value: 'futureCompetenceConcerns' },
  { text: 'Työntekijän persoona vaikuttaa työyhteisöön tai työssä suoriutumiseen', value: 'personalityConcerns' },
  // { text: 'Henkilö on ilmaissut toivovansa uutta työtä.', value: 'wantsNewJob' },
  { text: 'Henkilö on ilmaissut tyytymättömyytensä työpaikkaan', value: 'discontentWithEmployer' },
  { text: 'Jokin muu tilanne, kuvaa tarkemmin avoimessa kentässä', value: 'discontentWithCircumstances' },
];

const EmployerFeelsLike = ({ handleChange, stateValue }) => (
  <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
    <SecondaryHeader color="#393939">
      Kerro taustatiedot
    </SecondaryHeader>
    <FlexForm>
      {OPTIONS.map(({ text, value }) => (
        <InlineFlexContainer key={value} style={{ flexWrap: 'nowrap' }}>
          <CheckboxInput style={{ color: '#393939' }} name="feelings" value={value} checked={stateValue[value]} onChange={handleChange} />
          <CheckboxLabel style={{ color: '#393939' }}>{text}</CheckboxLabel>
        </InlineFlexContainer>
      ))}
    </FlexForm>
  </BasicFlexContainer>
);

EmployerFeelsLike.propTypes = {
  handleChange: PropTypes.func.isRequired,
  stateValue: PropTypes.shape({}).isRequired,
};

export default EmployerFeelsLike;
