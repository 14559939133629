/* global document */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import './index.css';
import './assets/fonts/value-black/stylesheet.css';
import Tuh from './containers/Tuh';
import Landing from './containers/Landing/Landing';
import Login from './containers/Login/Login';
import Employee from './containers/Employee/Employee';
import Register from './containers/Register/Register';
import PasswordResetContainer from './containers/PasswordReset/PasswordReset';
import Contacts from './components/employer/landing/Contacts';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_BACKEND_URL === 'https://xn--tyurahuolto-sfb.fi' ? 'GTM-WKVZRP8' : 'GTM-0000000',
};

TagManager.initialize(tagManagerArgs);

// Redirection after TYEL was shut down. TYEL's domain name was pointed
// to TUH's server, and this redirects the user to the page we want the
// user to end up at when they try to access TYEL.
console.log(process.env.REACT_APP_TYEL_HOSTNAME);
console.log(process.env.REACT_APP_TYEL_REDIRECTION_TARGET);
if (window.location.hostname === process.env.REACT_APP_TYEL_HOSTNAME) {
  window.location = `https://${process.env.REACT_APP_TYEL_REDIRECTION_TARGET}`;
}

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/employee/:token" component={Employee} />
        <Route exact path="/password_reset/:token" component={PasswordResetContainer} />
        <Route exact path="/tuh" component={Tuh} />
        <Route path="/" component={Landing} />
      </Switch>
    </BrowserRouter>
  </div>
);

ReactDOM.render(<App />, document.getElementById('root'));
