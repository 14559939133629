/* global fetch */

const backend = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';
const API_PREFIX = '/api/v1';

export const registerUser = async data => fetch(`${backend}${API_PREFIX}/employer/register`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
  method: 'POST',
}).then(resp => resp.json());

export const checkToken = async token => fetch(`${backend}/reset_password?token=${token}`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
}).then(resp => resp.json());

export const generatePWResetMail = async data => fetch(`${backend}/reset_password`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
  method: 'POST',
}).then(resp => resp.json());

export const resetPassword = async data => fetch(`${backend}/reset_password`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
  method: 'PUT',
}).then(resp => resp.json());
