/* eslint-disable  react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { ModalProvider } from 'styled-react-modal';
import { FadingBackground } from '../components/styled/modal';
import withAuth from '../service/authService/withAuth';
import Employer from './Employer/Employer';
import Admin from './Admin/Admin';

const Tuh = ({ user }) => {
  const { isAdmin } = user;
  return (
    <div>
      <ModalProvider backgroundComponent={FadingBackground}>
        {isAdmin
          ? <Admin />
          : <Employer />
        }
      </ModalProvider>
    </div>
  );
};

Tuh.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default withAuth(Tuh);
