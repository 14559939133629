/* global fetch */

const backend = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

export const startEmployeePath = async body => fetch(`${backend}/api/v1/employee/beginfeedback`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body,
  method: 'POST',
}).then((res) => {
  if (res.status >= 200 && res.status < 300) {
    return res.json();
  }
  throw new Error('Järjestelmän sisäinen virhe!');
});

export const updateFeedback = async (id, data) => fetch(`${backend}/api/v1/employee/feedback`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ id, data }),
  method: 'PUT',
}).then((res) => {
  if (res.status < 200 && res.status > 300) {
    throw new Error('Järjestelmän sisäinen virhe. Lomakkeen tallennus ei onnistunut.');
  }
  return res;
});

export const checkToken = async token => fetch(`${backend}/api/v1/employee/check_token`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ token }),
  method: 'POST',
});
