/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InlineFlexContainer, LandingFlexContainer, FormRowContainer,
} from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import { PrimaryHeader, SecondaryHeader } from '../../styled/headers';
import { TextInput, InputLabel } from '../../styled/inputs';
import validateForm from '../../../service/validation/formValidator';
import { formValidationRules } from './validationRules';
import BackgroundImage1 from '../../BackgroundImage1/BackgroundImage1';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  birthdate: '',
  phone: '',
  email: '',
  location: '',
  errors: null,
};

class RegisterForm extends Component {
  constructor() {
    super();
    this.state = Object.assign({}, INITIAL_STATE);
  }

  handleRegister = () => {
    const data = Object.assign({}, this.state);
    const errors = validateForm(data, formValidationRules);
    if (!this.validatePassword(data.password)) {
      // eslint-disable-next-line no-undef
      alert('Salasanan vähimmäispituus on 12 merkkiä, ja sen tulee sisältää pieniä ja isoja kirjaimia, numeroita sekä erikoismerkkejä.');
      return false;
    }
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return this.setState({ errors });
    }
    if (data.errors) {
      delete data.errors;
    }
    Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);
    const { endJourney } = this.props;
    return endJourney(data);
  }

  validatePassword = (password) => {
    let match;

    // length
    if (password.length < 12) {
      return false;
    }

    // lower
    match = password.match(/[a-zäöå]{1,}/g);
    if (!match) {
      return false;
    }

    // UPPER
    match = password.match(/[A-ZÄÖÄÅ]{1,}/g);
    if (!match) {
      return false;
    }

    // num
    match = password.match(/[0-9]{1,}/g);
    if (!match) {
      return false;
    }

    // special
    match = password.match(/[^0-9a-zA-ZäöåÄÖÅ]{1,}/g);
    if (!match) {
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {
    const stateValue = this.state;
    const { errors } = stateValue;
    return (
      <LandingFlexContainer direction="column" style={{ flexDirection: 'column' }}>
        <BackgroundImage1 />
        <PrimaryHeader>
          Luo työurahuolto tunnus.
        </PrimaryHeader>
        <SecondaryHeader>
          Luo itsellesi henkilökohtainen tunnus jolla voit kirjautua palveluun.
        </SecondaryHeader>
        <div
          style={{
          }}
        >
          <strong style={{ verticalAlign: 'sub' }}>*</strong>
          {' '}
          - pakollinen tieto
        </div>
        <p>
          <i>
            Salasanan vähimmäispituus on 12 merkkiä. Salasanan tulee sisältää isoja ja
            pieniä kirjaimia, numeroita sekä erikoismerkkejä.
          </i>
        </p>
        <InlineFlexContainer style={{ paddingTop: '3rem' }}>
          <FormRowContainer>
            <InputLabel error={errors && errors.has('firstName')}>Etunimi *</InputLabel>
            <TextInput
              name="firstName"
              type="text"
              value={stateValue.firstName}
              onChange={this.handleChange}
            />
          </FormRowContainer>
          <FormRowContainer>
            <InputLabel error={errors && errors.has('lastName')}>Sukunimi *</InputLabel>
            <TextInput
              name="lastName"
              type="text"
              value={stateValue.lastName}
              onChange={this.handleChange}
            />
          </FormRowContainer>
        </InlineFlexContainer>
        <InlineFlexContainer>
          <FormRowContainer>
            <InputLabel error={errors && errors.has('email')}>Sähköposti *</InputLabel>
            <TextInput
              name="email"
              type="text"
              value={stateValue.email}
              onChange={this.handleChange}
            />
          </FormRowContainer>
          <FormRowContainer>
            <InputLabel>Puhelinnumero</InputLabel>
            <TextInput
              name="phone"
              type="text"
              value={stateValue.phone}
              onChange={this.handleChange}
            />
          </FormRowContainer>
        </InlineFlexContainer>
        <InlineFlexContainer>
          <FormRowContainer>
            <InputLabel>Osasto (valinnainen)</InputLabel>
            <TextInput
              name="department"
              type="text"
              value={stateValue.department}
              onChange={this.handleChange}
            />
          </FormRowContainer>
          <FormRowContainer>
            <InputLabel error={errors && errors.has('password')}>Salasana *</InputLabel>
            <TextInput
              name="password"
              type="password"
              value={stateValue.password}
              onChange={this.handleChange}
            />
          </FormRowContainer>
        </InlineFlexContainer>
        <div style={{ paddingTop: '3rem' }}>
          <PillButton onClick={this.handleRegister}>Rekisteröidy</PillButton>
        </div>
      </LandingFlexContainer>
    );
  }
}

RegisterForm.propTypes = {
  endJourney: PropTypes.func.isRequired,
};

export default RegisterForm;
