import React, { Component } from 'react';
import { BasicFlexContainer } from '../../styled/containers';
import { TableTextInput } from '../../styled/inputs';
import { getUsers } from '../../../service/transport/admin';
import AddUserModal from './AddUserModal';
import EditModal from './EditModal';
import Table from '../../Table/Table';
import ModalButton from './ModalButton';
import { PillButton, MoreButton, MoreButtonDot } from '../../styled/buttons';
import searchIcon from '../../../assets/Search.svg';

const MoreButtonDots = () => (
  <div>
    <MoreButtonDot />
    <MoreButtonDot />
    <MoreButtonDot />
  </div>
);

const mapUserToRole = (user) => {
  if (user.isAdmin) return 'Admin';
  if (user.isCustomerHrAdmin) return 'Hr-Admin';
  return 'Käyttäjä';
};

const columns = afterCloseFunc => ([
  {
    id: 'name',
    Header: 'Nimi',
    accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    minWidth: 50,
  },
  {
    id: 'phone',
    Header: 'Puhelin',
    accessor: 'phone',
    minWidth: 50,
  },
  {
    id: 'email',
    Header: 'Sähköposti',
    accessor: 'email',
    minWidth: 120,
  },
  {
    id: 'customer',
    Header: 'Yritys',
    accessor: ({ customer }) => (customer && customer.name) || '',
    minWidth: 50,
  },
  {
    id: 'department',
    Header: 'Osasto',
    accessor: 'department',
    minWidth: 50,
  },
  {
    id: 'role',
    Header: 'Rooli',
    resizable: false,
    accessor: '',
    Cell: ({ row }) => (<span>{mapUserToRole(row.original)}</span>),
    minWidth: 80,
  },
  {
    resizable: false,
    sortable: false,
    id: 'editUser',
    Header: '',
    accessor: '',
    minWidth: 40,
    Cell: ({ row }) => (
      <ModalButton
        labelText={<MoreButtonDots />}
        Modal={EditModal}
        Button={MoreButton}
        buttonStyle={{ width: '50px' }}
        afterCloseFunc={afterCloseFunc}
        data={row.original}
      />
    ),
  }]);

const notNullAndIncludes = (str, value) => (
  str && str.toLowerCase().includes(value.toLowerCase())
);

class Users extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      filterText: '',
    };
  }

  componentDidMount() {
    this.findUsers();
  }

  findUsers = () => getUsers().then(users => this.setState({ users }));

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {
    const {
      filterText, users,
    } = this.state;
    return (
      <BasicFlexContainer direction="column" style={{ flexDirection: 'column', padding: '8rem 10rem 8rem 10rem' }}>
        <BasicFlexContainer style={{
          background: '#ffffff',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          paddingRight: '1rem',
        }}
        >
          <div style={{ padding: '2.5rem 1.5rem 1rem 0' }}>
            <ModalButton
              buttonStyle={{
                background: '#379CF0', color: '#ffffff', width: '15rem',
              }}
              labelText="Luo uusi käyttäjä"
              Modal={AddUserModal}
              Button={PillButton}
              afterCloseFunc={this.findUsers}
            />
          </div>
          <BasicFlexContainer direction="column" style={{ flexDirection: 'column', padding: '0 0 0.2rem 0', position: 'relative' }}>
            <TableTextInput
              placeholder="Etsi"
              name="filterText"
              onChange={this.handleChange}
              value={filterText}
              style={{
                boxShadow: '0, 2, 2, 0 rgba(11, 11, 11, 0.27)', border: 'solid 1 #e5e5e5', borderRadius: 3, marginTop: '2.9rem',
              }}
            />
          </BasicFlexContainer>
        </BasicFlexContainer>
        <Table
          data={users.filter((dFiltered) => {
            if (!filterText || !dFiltered) return dFiltered;
            return notNullAndIncludes(dFiltered.customerName, filterText)
              || notNullAndIncludes(dFiltered.firstName, filterText)
              || notNullAndIncludes(dFiltered.lastName, filterText)
              || notNullAndIncludes(mapUserToRole(dFiltered), filterText)
              || notNullAndIncludes(dFiltered.phone, filterText)
              || notNullAndIncludes(dFiltered.department, filterText)
              || notNullAndIncludes(dFiltered.email, filterText)
              || notNullAndIncludes(dFiltered.customer ? dFiltered.customer.name : 'Ei tiedossa', filterText);
          })}
          click={() => {}}
          columns={columns(this.findUsers)}
        />
      </BasicFlexContainer>
    );
  }
}

export default Users;
