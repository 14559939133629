/* global fetch */

import AuthService from '../authService/authService';

const Auth = new AuthService(process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002');

const backend = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';
const API_PREFIX = '/api/v1';

export const postCase = async data => Auth.fetch(`${backend}${API_PREFIX}/employer/cases`, {
  body: JSON.stringify(data),
  method: 'POST',
});

export const getCases = async () => Auth.fetch(`${backend}${API_PREFIX}/employer/cases`, {
  method: 'GET',
});

export const sendKeyCode = async key => fetch(`${backend}${API_PREFIX}/employer/key`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ key }),
  method: 'POST',
}).then(resp => resp.json());
