import React, { Component } from 'react';
import propTypes from 'prop-types';
import Table from '../../Table/Table';
import classes from './Redirected.module.css';
import expanderIcon from '../../../assets/nuoli.svg';
import { getCases } from '../../../service/transport/employer';
import { Page } from '../../styled/containers';
import { TableTextInput } from '../../styled/inputs';
import searchIcon from '../../../assets/Search.svg';

const adminColumn = {
  id: 'user',
  Header: 'Käyttäjä',
  accessor: row => `${row.user.firstName} ${row.user.lastName}`,
};

const baseColumns = [
  {
    id: 'fullName',
    Header: 'Nimi',
    accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    id: 'customerName',
    Header: 'Yritys',
    accessor: 'customerName',
  },
  {
    id: 'phone',
    Header: 'Puhelin',
    accessor: 'phone',
  },
  {
    id: 'email',
    Header: 'Sähköposti',
    accessor: 'email',
  },
  {
    id: 'createdAt',
    Header: 'Lähetetty',
    accessor: 'createdAt',
    Cell: ({ row }) => {
      const date = new Date(row.values.createdAt);
      return <span className="string">{`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</span>; // Custom cell components!
    },
  },
  {
    id: 'latesCommission',
    Header: 'Toimenpide',
    accessor: 'latestCommission',
  }];

const subComponent = ({ row }) => {
  if (row.original.commissiondetails) {
    const {
      original: {
        commissiondetails: {
          employerReasons, employerOwnWords,
        },
      },
    } = row;
    return (
      <div>
        {employerReasons && employerReasons.length > 0
        && (
          <div style={{ padding: '1rem 0 1rem 2rem', display: 'flex', flexDirection: 'column' }}>
            <b>Ohjautumisen syyt</b>
            { employerReasons.map(r => (
              <span>
                {`- ${r}`}
              </span>
            ))}
            {employerOwnWords && (
              <span style={{ paddingTop: '.5rem' }}>
                <b>Omin sanoin: </b>
                {employerOwnWords}
              </span>
            ) }
          </div>
        )}
      </div>
    );
  }
  return null;
};

const notNullAndIncludes = (str, value) => (
  str && str.toLowerCase().includes(value.toLowerCase())
);

class Redirected extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      filterText: '',
    };
  }

  componentDidMount() {
    this.findCases();
  }

  findCases = () => getCases().then(cases => this.setState({ cases }));

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {
    const { showHrAdminView } = this.props;
    const { filterText, cases } = this.state;
    const initialState = {
      sortBy: [{ id: 'createdAt', desc: true }],
    };
    const columns = [...baseColumns];
    if (showHrAdminView) columns.splice(5, 0, adminColumn);
    return (
      <Page style={{ backgroundColor: '#ffffff' }}>
        <div style={{ float: 'right', marginTop: '6.7rem', marginRight: '6rem' }}>
          <br />
          <TableTextInput
            placeholder="Etsi"
            name="filterText"
            onChange={this.handleChange}
            value={filterText}
            style={{
              boxShadow: '0, 2, 2, 0 rgba(11, 11, 11, 0.27)', border: 'solid 1 #e5e5e5', borderRadius: 3, marginTop: '0.9rem',
            }}
          />
        </div>
        <div style={{ padding: '12rem 0', margin: '0 5rem', overflow: 'visible' }}>
          <Table
            data={cases.filter((dFiltered) => {
              if (!filterText || !dFiltered) return dFiltered;
              return notNullAndIncludes(dFiltered.customerName, filterText)
                || notNullAndIncludes(dFiltered.firstName, filterText)
                || notNullAndIncludes(dFiltered.lastName, filterText)
                || notNullAndIncludes(dFiltered.phone, filterText)
                || notNullAndIncludes(dFiltered.latestCommission, filterText)
                || notNullAndIncludes(dFiltered.department, filterText)
                || notNullAndIncludes(dFiltered.email, filterText)
                || notNullAndIncludes(dFiltered.customer ? dFiltered.customer.name : 'Ei tiedossa', filterText);
            })}
            click={() => {}}
            columns={columns}
            subComponent={subComponent}
            initialState={initialState}
          />
        </div>
      </Page>
    );
  }
}

Redirected.defaultProps = {
};

Redirected.propTypes = {
  showHrAdminView: propTypes.bool.isRequired,
};

export default Redirected;
