import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer, InlineFlexContainer, FormRowContainer } from '../../styled/containers';
import { SecondaryHeader } from '../../styled/headers';
import { TextInput, InputLabel } from '../../styled/inputs';

const BasicInfo = ({ handleChange, stateValue }) => (
  <BasicFlexContainer
    direction="column"
    style={{ flexDirection: 'column', justifyContent: 'space-between', padding: '1rem 0rem' }}
  >
    <InlineFlexContainer>
      <FormRowContainer direction="column" style={{ flexDirection: 'column' }}>
        <InputLabel
          style={{ color: '#393939' }}
          error={stateValue.errors && stateValue.errors.has('firstName')}
          htmlFor="firstName"
        >
          Etunimi *
        </InputLabel>
        <TextInput
          name="firstName"
          type="text"
          value={stateValue.firstName}
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel
          style={{ color: '#393939' }}
          error={stateValue.errors && stateValue.errors.has('lastName')}
          htmlFor="lastName"
        >
          Sukunimi *
        </InputLabel>
        <TextInput
          name="lastName"
          type="text"
          value={stateValue.lastName}
          onChange={handleChange}
        />
      </FormRowContainer>
    </InlineFlexContainer>
    <InlineFlexContainer>
      <FormRowContainer>
        <InputLabel
          style={{ color: '#393939' }}
          error={stateValue.errors && stateValue.errors.has('birthdate')}
          htmlFor="birthdate"
        >
          {stateValue.errors && stateValue.errors.has('birthdate')
            ? <span>{stateValue.errors.get('birthdate')}</span>
            : <span>Syntymäaika</span>
          }
        </InputLabel>
        <TextInput
          name="birthdate"
          type="text"
          value={stateValue.birthdate}
          onChange={handleChange}
        />
      </FormRowContainer>

      <FormRowContainer>
        <InputLabel
          style={{ color: '#393939' }}
          htmlFor="email"
          error={stateValue.errors && stateValue.errors.has('email')}
        >
          Sähköposti *
        </InputLabel>
        <TextInput
          name="email"
          type="text"
          value={stateValue.email}
          onChange={handleChange}
        />
      </FormRowContainer>
    </InlineFlexContainer>
    <InlineFlexContainer>
      <FormRowContainer>
        <InputLabel htmlFor="phone" style={{ color: '#393939' }}>Puhelinnumero</InputLabel>
        <TextInput
          name="phone"
          type="text"
          value={stateValue.phone}
          onChange={handleChange}
        />
      </FormRowContainer>

      <FormRowContainer>
        <InputLabel htmlFor="location" style={{ color: '#393939' }}>Kaupunki</InputLabel>
        <TextInput
          name="location"
          type="text"
          value={stateValue.location}
          onChange={handleChange}
        />
      </FormRowContainer>
    </InlineFlexContainer>
  </BasicFlexContainer>
);

BasicInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  stateValue: PropTypes.shape({}).isRequired,
};

export default BasicInfo;
