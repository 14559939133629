/* global alert */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sendKeyCode } from '../../service/transport/employer';
import { registerUser } from '../../service/transport/user';
import KeycodeLanding from '../../components/employer/register/Keycode';
import RegisterForm from '../../components/employer/register/RegisterForm';
import { InlineFlexContainer, Page, TopBar } from '../../components/styled/containers';
import BaronaFooter from '../../components/BaronaFooter/BaronaFooter';
import { NoStyleButton } from '../../components/styled/buttons';
import testIfInternetExplorer from '../../service/authService/ieTester';
import { SecondaryHeader } from '../../components/styled/headers';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      correctCode: false,
      customerId: null,
      key: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    testIfInternetExplorer();
  }

  componentWillUnmount() {
    this.setState({ correctCode: false, customerId: null });
  }

  continue = value => sendKeyCode(value)
    .then(({ status, customerId }) => {
      if (status === 'ok') this.setState({ correctCode: true, customerId, key: value });
      else {
        // eslint-disable-next-line no-console
        console.error('Virheellinen tunnistautuminen tai tunnistautuminen ei ole voimassa!');
        alert('Väärä avainkoodi.');
      }
    })

  finish = (data) => {
    const { customerId, key } = this.state;
    const registerData = Object.assign({ customerId, key }, data);
    return registerUser(registerData)
      .then((resp) => {
        const { history } = this.props;
        if (resp.status === 'ok') {
          this.setState({ correctCode: false, customerId: null, key: null });
          return history.push('/');
        }
        if (resp.message === 'User already exist by email!') return alert('Sähköpostiosoite on jo käytössä!');
        return alert('Tapahtui virhe! Ole ystävällinen ja yritä uudelleen.');
      });
  }

  render() {
    const { correctCode } = this.state;
    const { history } = this.props;
    return (
      <Page>
        <TopBar>
          <InlineFlexContainer style={{ width: '100vw', justifyContent: 'left', flexWrap: 'nowrap' }}>
            <div style={{ margin: '.5rem 0rem .5rem 1rem' }}>
              <NoStyleButton type="button" onClick={!correctCode ? () => history.replace('/') : () => {}}>
                <SecondaryHeader>Työurahuolto</SecondaryHeader>
              </NoStyleButton>
            </div>
          </InlineFlexContainer>
        </TopBar>
        {!correctCode
          ? (
            <KeycodeLanding
              handleBegin={this.continue}
            />
          )
          : (
            <RegisterForm
              endJourney={this.finish}
            />
          )}
      </Page>
    );
  }
}

Register.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Register;
