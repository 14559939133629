import styled from 'styled-components';

export const Page = styled.div`
  background-color: #379CF0;
  display: block;
  min-height: 100vh;

  @media (max-width: 1200px) {
    background-size: auto;
  }
`;

export const BasicFlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'}

  @media (max-width: 1200px) {
    flex-direction: column;
    .mobileBackground {
      margin-right: 15rem;
    }
  }
`;

export const LandingFlexContainer = styled(BasicFlexContainer)`
  padding: 20vh 0 20vh 10vw;
  max-width: 40vw;

  @media (max-width: 1200px) {
    padding: 10vh 10vw;
    max-width: 80vw;
  }
`;

export const PageFlexContainer = styled(LandingFlexContainer)`
  padding: 20vh 0 0 20vw;
  max-width: 60vw;
`;

export const FormRowContainer = styled(BasicFlexContainer)`
  margin-top: 30px;
  margin-right: 50px;
  flex-direction: column;
  `;

export const InlineFlexContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const TopBar = styled.div`
  // position: fixed;
  width: 100vw;
  background: #379CF0;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  font-size: 12px;
  overflow: hidden;
`;

export const Logo = styled.div`
  position: fixed;
  top: 25vh;
  right: 0;
  margin-right: 20vh;

  @media (max-width: 1200px) {
    position: relative;
    top: 5vh;
    width: 30vw;
    margin: 0 35vw;
  }

  & > img {
    width: 100%;
  }
`;

export const LinkNotValid = styled.div`
   position: absolute;
   height: 100%;
   width: 100%;
   background-color: #379CF0;
   display: flex;
   justify-content: center;
   align-items: center;
`;
