import React from 'react';
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  useSortBy,
} from 'react-table';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import expanderIcon from '../../assets/nuoli.svg';

import classes from './Table.module.css';
import './Table.css';

const Table = ({
  data,
  columns,
  subComponent,
  initialState,
  getTdProps = () => {},
  className = '',
}) => {
  const defaultColumn = {
    minWidth: 30,
    width: 50,
    maxWidth: 400,
  };
  if (subComponent && !columns.find(column => column.id === 'expander')) {
    columns.unshift({
      Header: '',
      id: 'expander',
      resizable: false,
      className: 'text-center',
      width: 50,
      Cell: ({ row }) => (
        <div
          {...row.getToggleRowExpandedProps()}
          style={{ whiteSpace: 'unset' }}
          className="rt-td"
        >
          <div>
            {row.isExpanded
              ? <img alt="Lisätiedot auki" style={{ transform: 'rotate(180deg)' }} src={expanderIcon} />
              : <img alt="Lisätiedot kiinni" src={expanderIcon} />
            }
          </div>
        </div>
      ),
    });
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      subComponent,
      defaultColumn,
      initialState,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
  );

  return (
    <div className={classnames(className, 'Table')}>
      <div {...getTableProps} className="rt-table">
        <div className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className={classes.TableHeaders}>
              {headerGroup.headers.map((column) => (
                column.resizable === false ? (
                  <div {...column.getHeaderProps(column.getSortByToggleProps())} className="rt-th">
                    {column.render('Header')}
                  </div>
                ) : (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render('Header')}
                    </div>
                    <div {...column.getResizerProps()} />
                  </div>
                )
              ))}
            </div>
          ))}
        </div>
        <div className="rt-tbody">
          <div {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <div
                    className={classnames(classes.TableRow)}
                  >
                    <div {...row.getRowProps()}>
                      {row.cells.map((cell, i) => (
                        <div
                          {...getTdProps(cell)}
                          {...cell.getCellProps()}
                          className={classnames('rt-td', i !== 0 ? 'tooltip' : '', cell.column.className)}
                        >
                          <div className="rt-td-element">
                            {cell.render('Cell')}
                          </div>
                          {i !== 0 && (
                            <span className="tooltip-text">
                              {cell.render('Cell')}
                            </span>
                          )
                          }
                        </div>
                      ))}
                    </div>
                    <div>
                      {row.isExpanded ? subComponent({ row }) : null}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
Table.defaultProps = {
  subComponent: null,
  click: null,
  filterable: false,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  click: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  subComponent: PropTypes.func,
  filterable: PropTypes.bool,
};

export default Table;
