/* global localStorage, fetch */

import decode from 'jwt-decode';

export default class AuthService {
  constructor(domain) {
    this.domain = domain || 'http://localhost:3002';
  }

  logIn = (email, password) => (
    this.fetch(`${this.domain}/signin`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((res) => {
      this.setToken(res.token);
      return Promise.resolve(res);
    })
  )

  loggedIn = () => {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  setToken = (idToken) => {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
  }

  setActiveTab = (idx) => {
    // Saves user token to localStorage
    localStorage.setItem('tabIndex', idx);
  }

  getActiveTab = () => localStorage.getItem('tabIndex')

  getToken = () => localStorage.getItem('id_token')

  logout = () => localStorage.removeItem('id_token')

  getProfile = () => {
    const token = this.getToken();
    return token && decode(token);
  }

  fetch = (url, options, initHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }) => {
    const headers = initHeaders;

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this.checkStatus)
      .then(response => response.json());
  }

  checkStatus = async (response) => {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return response;
    }
    const parsedError = await response.json();
    const error = new Error(parsedError.message || response.statusText);
    error.response = response;
    throw error;
  }
}
