import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import classes from './DropdownMenu.module.css';

const reactSelectStyle = {
  control: provided => ({
    ...provided,
    borderRadius: '0px',
    boxShadow: 'inset 0 0 3px 0 rgba(0, 0, 0, 0.5)',
    border: 'none',
  }),
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? '.5' : '1',
    backgroundColor: 'transparent',
  }),
};

const dropdownMenu = ({
  isDisabled = false,
  value,
  options,
  name,
  onChange,
  nameLeft,
  style,
  isClearable,
  isMulti,
  nameClassName,
  error,
  errorStyle,
}) => (
  <div className={classes.DropdownMenu} style={style}>
    <div style={(error && { color: 'red' }) || {}} className={nameClassName || classes.NameText}>{name}</div>
    <div
      className={classes.Selector}
      style={{
        width: nameLeft ? '75%' : '100%',
        marginLeft: nameLeft ? '20px' : '0px',
        marginBottom: nameLeft ? '0px' : '10px',
      }}
    >
      <Select
        isDisabled={isDisabled}
        isClearable={isClearable}
        placeholder="Valitse..."
        value={isDisabled ? '' : value}
        options={options}
        onChange={onChange}
        styles={reactSelectStyle}
        isMulti={isMulti}
      />
      {error && ((error.length
        && error.map(e => (
          <span className={classes.ErrorText} style={{ ...errorStyle }}>{e}</span>
        )))
        || (<span className={classes.ErrorText} style={{ ...errorStyle }}>{error}</span>))}
    </div>
  </div>
);

dropdownMenu.defaultProps = {
  isDisabled: false,
  nameLeft: false,
  style: {},
  isClearable: false,
  value: undefined,
  isMulti: false,
  nameClassName: null,
  error: null,
  errorStyle: {},
};

dropdownMenu.propTypes = {
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })),
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  nameLeft: PropTypes.bool,
  style: PropTypes.shape({}),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  nameClassName: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  errorStyle: PropTypes.shape({}),
};

export default dropdownMenu;
