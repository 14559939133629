import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '../../styled/modal';

const ModalButton = ({
  Modal, style = {}, labelText, Button, afterCloseFunc, data = {}, buttonStyle = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const toggleModal = () => {
    if (isOpen) afterCloseFunc();
    setIsOpen(!isOpen);
  };

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  };

  const beforeClose = () => new Promise((resolve) => {
    setOpacity(0);
    setTimeout(resolve, 200);
  });

  return (
    <div style={style}>
      <Button style={buttonStyle} onClick={toggleModal}>{labelText}</Button>
      {isOpen
      && (
      <BasicModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <Modal data={data} closeModal={toggleModal} />
      </BasicModal>
      )
      }
    </div>
  );
};

ModalButton.propTypes = {
  Modal: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
  labelText: PropTypes.string || PropTypes.shape({}),
  Button: PropTypes.node.isRequired,
  afterCloseFunc: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  buttonStyle: PropTypes.shape({}),
};

ModalButton.defaultProps = {
  data: {},
  buttonStyle: {},
  labelText: '',
  style: {},
};

export default ModalButton;
