import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BasicFlexContainer, Page } from '../../styled/containers';
import { PrimaryHeader, SecondaryHeader } from '../../styled/headers';
import background from '../../../assets/tyourahuolto_taustakuva.jpg';
import baronaLogo from '../../../assets/barona_blue_rgb.png';

const ContactsPage = styled(Page)`
  background: url(${background});
  background-size: 100%;
  background-attachment: fixed;
  color: #343331;

  @media (max-width: 1300px) {
    background: url(${background});
  }
`;

const ResponsiveFlexContainer = styled(BasicFlexContainer)`
  flex-direction: row;
  margin-left: 2rem;
  margin-top: 1rem;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
    margin-left:
  }
`;

const StyledHeader = styled(SecondaryHeader)`
  color: #343331;
  margin: 14px 0;
  font-family: 'Inter-Bold';
`;

const Contacts = ({ handleBegin }) => ( // eslint-disable-line
  <ContactsPage style={{ overflow: 'hidden' }}>
    <BasicFlexContainer>
      <Link to="/" style={{ marginLeft: '1.5rem', paddingTop: '1rem', textDecoration: 'none' }}>
        <SecondaryHeader>Työurahuolto</SecondaryHeader>
      </Link>
    </BasicFlexContainer>
    <ResponsiveFlexContainer>
      <BasicFlexContainer style={{ flexDirection: 'column' }}>
        <PrimaryHeader style={{ color: '#343331', marginLeft: '2.2rem', marginBottom: '2.2rem' }}>
          Ota yhteyttä!
        </PrimaryHeader>
        <ResponsiveFlexContainer>
          <BasicFlexContainer style={{ flexDirection: 'column' }}>
            <StyledHeader>Aapo Koski</StyledHeader>
            <a href="mailto:aapo.koski@barona.fi" style={{ textDecoration: 'none', color: '#343331' }}><p>aapo.koski@barona.fi</p></a>
            <p>0503098265</p>
          </BasicFlexContainer>
          <BasicFlexContainer style={{ flexDirection: 'column', paddingBottom: '1rem', paddingRight: '2rem' }}>
            <StyledHeader>Kalle Pulkkanen</StyledHeader>
            <a href="mailto: kalle.pulkkanen@barona.fi" style={{ textDecoration: 'none', color: '#343331' }}><p>kalle.pulkkanen@barona.fi</p></a>
            <p>0438244194</p>
          </BasicFlexContainer>
        </ResponsiveFlexContainer>
      </BasicFlexContainer>
    </ResponsiveFlexContainer>
    <a href="https://barona.fi/palvelu/tyokykypalvelut/">
      <img
        src={baronaLogo}
        alt=""
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          height: '8vw',
          width: 'auto',
        }}
      />
    </a>
  </ContactsPage>
);

Contacts.propTypes = {
  handleBegin: PropTypes.func.isRequired,
};

export default Contacts;
