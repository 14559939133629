/* global document, window, alert */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PillButton } from '../../styled/buttons';
import { InlineFlexContainer, BasicFlexContainer } from '../../styled/containers';
import { updateUser, deactivateUser } from '../../../service/transport/admin';
import FlexForm from '../../styled/FlexForm';
import { TableInputLabel, TableTextInput } from '../../styled/inputs';
import { SecondaryHeader, ModalFormHeader } from '../../styled/headers';
import Dropdownmenu from '../../DropdownMenu/DropdownMenu';
import validateForm from '../../../service/validation/formValidator';
import { editUserValidationRules } from './validationRules';

const roles = [
  { label: 'Esimies', value: '' },
  { label: 'Barona admin', value: 'isAdmin' },
  { label: 'Hr admin', value: 'isCustomerHrAdmin' },
];

const mapRoleToDropDown = (user) => {
  if (user.isAdmin) return roles[1];
  if (user.isCustomerHrAdmin) return roles[2];
  return roles[0];
};

const EditModal = ({
  data: {
    isAdmin,
    isCustomerHrAdmin,
    firstName, email, lastName, id,
  },
  closeModal,
}) => {
  const [form, setValues] = useState({
    id,
    isAdmin,
    isCustomerHrAdmin,
    firstName,
    lastName,
    email,
    errors: null,
  });

  const handleUpdate = async (event) => {
    event.preventDefault();
    const data = { ...form };
    const errors = validateForm(data, editUserValidationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return setValues({
        ...form,
        errors,
      });
    }
    if (data.errors) {
      setValues({
        ...form,
        errors: null,
      });
    }
    if (data.isAdmin && !window.confirm(`Olet antamassa käyttäjälle Barona admin -roolin,
       jolla on näkymä kaikkien yritysten kaikkiin tietoihin. Haluatko varmasti jatkaa?`)) {
      return true;
    }
    Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);
    try {
      await updateUser(data);
      return closeModal();
    } catch (e) {
      return alert(e);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!window.confirm('Haluatko varmasti poistaa käyttäjän?')) return;
    const { id: userId } = form;
    await deactivateUser(userId);
    closeModal();
  };

  const updateField = (e) => {
    if (!e.target && e.label) {
      let updatedValues;
      if (typeof e.value === 'number') {
        updatedValues = Object.assign(
          {},
          form,
          { customerId: e.value },
        );
      } else {
        updatedValues = Object.assign(
          {},
          form,
          { isAdmin: false, isCustomerHrAdmin: false },
          e.value ? { [e.value]: true } : {},
        );
      }
      return setValues({
        ...updatedValues,
      });
    }
    return setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FlexForm onSubmit={handleUpdate}>
      <BasicFlexContainer direction="column" style={{ flexDirection: 'column', width: '28rem' }}>
        <ModalFormHeader color="#379CF0">
          Muokkaa tietoja
        </ModalFormHeader>
        <SecondaryHeader color="#353535" style={{ paddingBottom: '3rem' }}>
          Voit muokata käyttäjän tietoja. Muokkaukset tulevat voimaan välittömästi.
        </SecondaryHeader>
        <div
          style={{ color: '#353535', fontSize: '14px', paddingBottom: '1rem' }}
        >
          <strong style={{ verticalAlign: 'sub' }}>*</strong>
          {' '}
          - pakollinen tieto
        </div>
      </BasicFlexContainer>
      <InlineFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column', marginRight: '1.5rem' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('firstName')}>Etunimi *</TableInputLabel>
          <TableTextInput
            name="firstName"
            type="text"
            value={form.firstName}
            onChange={updateField}
          />
        </BasicFlexContainer>
        <BasicFlexContainer
          direction="column"
          style={{
            flexDirection: 'column',
          }}
        >
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('lastName')}>Sukunimi *</TableInputLabel>
          <TableTextInput
            name="lastName"
            type="text"
            value={form.lastName}
            onChange={updateField}
          />
        </BasicFlexContainer>
      </InlineFlexContainer>
      <InlineFlexContainer>
        <Dropdownmenu
          style={{
            marginTop: '0.8rem',
            width: '13.4rem',
            fontSize: '14px',
            padding: '0 1.5rem 0.2rem 0',
            color: '#353535',
          }}
          name="Rooli"
          options={roles}
          value={mapRoleToDropDown(form)}
          onChange={updateField}
        />
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('email')}>Sähköposti * (tunnus)</TableInputLabel>
          <TableTextInput
            name="email"
            type="text"
            value={form.email}
            onChange={updateField}
          />
        </BasicFlexContainer>
      </InlineFlexContainer>
      <InlineFlexContainer style={{ paddingTop: '2rem' }}>
        <PillButton style={{ width: '13.7rem', marginRight: '1.5rem', border: 'solid 2px #cccccc' }} onClick={closeModal}>Peruuta</PillButton>
        <PillButton style={{ width: '13.7rem' }} blue>Tallenna</PillButton>
      </InlineFlexContainer>
      <PillButton style={{ width: '13.7rem', marginTop: '1rem' }} red onClick={handleDelete}>Poista käyttäjä</PillButton>

    </FlexForm>
  );
};

EditModal.propTypes = {
  data: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
    isCustomerHrAdmin: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditModal;
