import styled from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';

export const BasicModal = Modal.styled`
  padding: 3rem 3rem;
  width: 33rem;
  display: flex;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
`;

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`;
