import React from 'react';

import AuthContext from './AuthContext';

export default Component => (
  React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
      {({ user, ...rest }) => <Component {...rest} {...props} user={user} ref={ref} />}
    </AuthContext.Consumer>
  ))
);
