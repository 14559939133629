export const keyValidationRules = {
  password: 'required',
};

export const formValidationRules = {
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  password: 'required',
};
