import Validator from 'validatorjs';

Validator.useLang('fi');

export const defaultAttributeNames = {
  name: 'Työntekijäryhmän nimi',
  tykyCustomerId: 'Yritys',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  phoneNumber: 'Puhelinnumero',
  roleId: 'Rooli',
  customerId: 'Yritys',
  socialSecurityNumber: 'Henkilötunnus',
  email: '',
  addedGroups: 'Ryhmät',
  'commissionType.value': 'Palvelu',
  'recipients.length': 'vastaanottaja',
  message: 'Viesti',
  commisiontype: 'Toimenpide',
  birthdate: 'Syntymäaika',
};

const birthDate = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

Validator.register('birthdate', value => value.match(birthDate),
  ':attribute tulee olla muotoa pp.kk.vvvv.');

export default (fields, rules, customMessages = {}) => {
  const validation = new Validator(fields, rules, customMessages);
  validation.setAttributeNames(defaultAttributeNames);
  if (validation.passes()) return false;
  return validation.errors;
};
