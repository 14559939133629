import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer } from '../../styled/containers';
import { PrimaryHeader } from '../../styled/headers';
import LandingPage from '../../../assets/BackgroundStairs.jpg';

const Landing = ({ handleBegin }) => ( // eslint-disable-line
  <BasicFlexContainer direction="row" style={{ marginLeft: 150, marginTop: 70, maxWidth: '40vw' }}>
    <BasicFlexContainer
      direction="column"
      style={{ flexDirection: 'column' }}
    >
      <PrimaryHeader>
        Tervetuloa!
      </PrimaryHeader>
      <br />
      <span>
        Työurahuolto auttaa työntekijöitä ja
        yrityksiä ratkomaan erilaisia uraan liittyviä haasteita.
        <br />
        Meillä on useita keinoja auttaa erilaisissa tilanteissa -
        valmennamme vuosittain yli tuhat ihmistä kohti mielekkäämpää työelämää.
        <br />
        <br />
        Voit kutsua työntekijöitä palveluun Ohjaa palveluun -kohdasta,
        jonka jälkeen ammattitaitoiset työuravalmentajamme
        ryhtyvät toimeen tilanteen ratkaisemiseksi.
      </span>
    </BasicFlexContainer>
  </BasicFlexContainer>
);

Landing.propTypes = {
  handleBegin: PropTypes.func.isRequired,
};

export default Landing;
