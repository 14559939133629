import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Page, BasicFlexContainer, InlineFlexContainer, TopBar, LandingFlexContainer,
} from '../../components/styled/containers';
import { PrimaryHeader, SecondaryHeader } from '../../components/styled/headers';
import background from '../../assets/tyourahuolto_taustakuva.jpg';
import baronaLogo from '../../assets/barona_blue_rgb.png';

const LandingPageContainer = styled(Page)`
  background: url(${background});
  background-size: 100%;
  background-attachment: fixed;

  @media (max-width: 1300px) {
    background: url(${background});
  }
`;

const LandingPageTextBlock = styled.div`
  color: #343331;
  margin-left: 5.5rem;
  max-width: 55rem;

  @media (max-width: 800px) {
    margin: 0 2rem;
    font-size: 14px;
  }
`;

const LandingHeader = styled(PrimaryHeader)`
  color: #343331;
  margin-left: 5.5rem;
  margin-top: 4.5rem;
  padding-bottom: 2rem;

  @media (max-width: 800px) {
    margin: 2rem 2rem;
  }
`;

const LandingInlineFlexContainer = styled(InlineFlexContainer)`
  width: 100vw;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: 800px) {
    margin: 0rem 0rem;
    justify-content: space-between;
    justify-content: flex-end;
  }
`;

const ShortExplained = styled.div`
  padding-bottom: 2rem;
`;

const FrontPageButton = styled.button`
  width: 320px;
  height: 52px;
  border-radius: 4px;
  background-color: #379cf0;
  color: #ffffff;
  font-size: 0.9rem;
  font-family: 'Inter-Bold';
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 2rem;
  margin-right: 1.3rem;

  @media (max-width: 800px) {
    margin: 1rem 0;
    float: left !important;
  }
`;

const ButtonContainer = styled.div`
  flexDirection: row;
  paddingBottom: 2rem;

  @media (max-width: 900px) {
    align-items: flex-start;
  }
`;

const ResponsiveSecondaryHeader = styled(SecondaryHeader)`
  @media (max-width: 800px) {
    display: none;
  }
`;
// eslint-disable-next-line react/prefer-stateless-function
class Landing extends Component {
  render() {
    return (
      <LandingPageContainer>
        <TopBar style={{ background: 'none', paddingTop: 0 }}>
          <LandingInlineFlexContainer>
            <ResponsiveSecondaryHeader style={{ marginLeft: '1.5rem', marginBottom: '1.0rem' }}>Työurahuolto</ResponsiveSecondaryHeader>
            <InlineFlexContainer>
              <Link to="/tuh">
                <FrontPageButton style={{
                  width: '130px', float: 'right', marginRight: '1.3rem', marginTop: '1.3rem', color: '#379CF0', backgroundColor: '#ffffff',
                }}
                >
                  Kirjaudu
                </FrontPageButton>
              </Link>
              <Link to="/contacts">
                <FrontPageButton style={{
                  width: '130px', float: 'right', marginRight: '1.3rem', marginTop: '1.3rem', color: '#ffffff', backgroundColor: '#379CF0',
                }}
                >
                  Ota yhteyttä
                </FrontPageButton>
              </Link>
            </InlineFlexContainer>
          </LandingInlineFlexContainer>
        </TopBar>
        <BasicFlexContainer style={{ paddingBottom: '8rem' }}>
          <div style={{ overflow: 'hidden' }}>
            <LandingHeader>
              Baronan Työurahuolto
              <br />
              {' '}
              - uudenlainen tapa työuran
              <br />
              haasteiden ratkaisuun
            </LandingHeader>
            <LandingPageTextBlock>
              <p style={{ paddingBottom: '2rem' }}>
                Baronan Työurahuollon tavoitteena on edistää työntekijöiden ja
                työyhteisöjen hyvinvointia uudella ja yksilöä entistäkin huomioivammalla tavalla.
                Työurahuolto on palvelu työn ja elämän solmukohtien ratkaisuun
                sekä työurien kokonaisvaltaiseen tukemiseen.
                <br />
                Haluatko kuulla lisää? Asiantuntijamme kertovat mielellään lisää palvelusta.
              </p>
              <ShortExplained>
                <p>Lyhyesti:</p>
                <ul>
                  <li>
                    Työurahuolto antaa mahdollisuuden henkilölle tuoda
                    luottamuksellisesti esiin kaikki työkykyyn,
                    työntekoon ja työuraan liittyvät tekijät ja mahdollistaa
                    näiden käsittely työurahuollon ammattilaisen ohjauksessa
                  </li>
                  <li>
                    Palveluun hakeudutaan joko työnantajan
                    edustajan ehdotuksesta tai työntekijän aloitteesta
                  </li>
                  <li>Baronan kehittämä ja tuottama jatkuvan palvelun malli</li>
                </ul>
              </ShortExplained>
              <InlineFlexContainer style={{ width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                <ButtonContainer>
                  <a href="https://barona.fi/koulutukset-ja-valmennukset/tyourahuolto-tyontekijoille">
                    <FrontPageButton>Työntekijä - haluatko kuulla lisää?</FrontPageButton>
                  </a>
                  <a href="https://barona.fi/yrityksille/konsultointi/tyourahuolto">
                    <FrontPageButton>Työnantaja - haluatko kuulla lisää?</FrontPageButton>
                  </a>
                </ButtonContainer>
              </InlineFlexContainer>
              <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                <p style={{ fontFamily: 'Inter-Bold' }}>
                  Onko yrityksessänne käytössä jo työurahuolto? Kirjaudu sisään palveluun.
                </p>
                <Link to="/tuh">
                  <FrontPageButton style={{
                    width: '140px', marginLeft: '0', color: '#379CF0', backgroundColor: '#ffffff',
                  }}
                  >
                    Kirjaudu
                  </FrontPageButton>
                </Link>
              </div>
            </LandingPageTextBlock>
          </div>
        </BasicFlexContainer>
        <a href="https://barona.fi/palvelu/tyokykypalvelut/">
          <img src={baronaLogo} alt="" style={{ height: '8vw', width: 'auto' }} />
        </a>
      </LandingPageContainer>
    );
  }
}

export default Landing;
