import React from 'react';
import { PrimaryHeader } from '../../styled/headers';
import { BasicFlexContainer, LandingFlexContainer, Page } from '../../styled/containers';
import BaronaFooter from '../../BaronaFooter/BaronaFooter';
import ThankYouImage from '../../ThankYouImage/ThankYouImage';

const finish = () => (
  <Page>
    <ThankYouImage />
    <LandingFlexContainer direction="column" style={{ flexDirection: 'column' }}>
      <PrimaryHeader>
        Kiitos! Olemme sinuun pian yhteydessä.
      </PrimaryHeader>
      <br />
      <BasicFlexContainer>
        <span>
          Jos sinulla on kysymyksiä, voit olla yhteydessä
          <a style={{ fontWeight: '900', color: '#ffffff', textDecoration: 'none' }} href="mailto:tyourahuolto@barona.fi"> tyourahuolto@barona.fi</a>
          .
          Voit nyt poistua tältä sivulta ja jäädä odottamaan työurahuollon yhteydenottoa.
        </span>
      </BasicFlexContainer>
    </LandingFlexContainer>
    <BaronaFooter position="fixed" />
  </Page>
);

export default finish;
