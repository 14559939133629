import React from 'react';
import { Footer } from '../styled/containers';
import baronaLogo from '../../assets/barona_blue_rgb.png';

const BaronaFooter = props => (
  <Footer style={{ ...props }}>
    <a href="https://barona.fi/palvelu/tyokykypalvelut/">
      <img src={baronaLogo} alt="" style={{ height: '8vw', width: '100%' }} />
    </a>
  </Footer>
);

export default BaronaFooter;
