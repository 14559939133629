import styled from 'styled-components';

export const PillButton = styled.button`
  width: 175px;
  height: 52px;
  border-radius: 30.6px;
  background-color: #ffffff;
  color: #696969;
  font-size: 0.9rem;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  outline: none;
  
  ${props => props.blue && `
    background: #379CF0;
    color: #ffffff;
    font-size: 14px;
  `}
   ${props => props.red && `
    background: #eb6b6b;
    color: #ffffff;
    font-size: 14px;
  `}
`;

export const RectangleButton = styled.button`
  width: 231px;
  height: 55px;
  background-color: #379CF0;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: center;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  :hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

   ${({ active }) => active && `
      background-color: #ffffff;
      color: #379CF0
  `}
`;
export const NoStyleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const MoreButton = styled.button`
  background: none;
  border: 0 none;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 100%;
  text-align: left;
  outline: none;
  cursor: pointer;
`;

export const MoreButtonDot = styled.div`
    display: inline-block;
    background-color: #5c5c5c;
    width: 7px;
    height: 7px;
    margin-right: 4px;
    border-radius: 50%;
    transition: background-color 0.3s;
`;
