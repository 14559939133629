import React, { Component } from 'react';
import propTypes from 'prop-types';
import { InlineFlexContainer, Page, TopBar } from '../../components/styled/containers';
import { RectangleButton, NoStyleButton } from '../../components/styled/buttons';
import { SecondaryHeader } from '../../components/styled/headers';
import withAuthContext from '../../contexts/withAuthContext';
import Landing from '../../components/employer/landing/Landing';
import EmployerForm from '../../components/employer/redirect/EmployerForm';
import Redirected from '../../components/employer/redirected/Redirected';
import Settings from '../../components/employer/settings/Settings';
import testIfInternetExplorer from '../../service/authService/ieTester';
import BackgroundImage from '../../assets/BackgroundStairs.jpg';

class Employer extends Component {
  state = {
    activeTab: 0, // eslint-disable-line
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    testIfInternetExplorer();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { getTab } = newProps;
    this.setState({ activeTab: getTab() });
  }

  mapReturnableView = (idx, isHrAdmin) => {
    switch (idx) {
      case '1':
        return <EmployerForm goToRedirectedTable={() => this.changeTab({ target: { value: '2' } })} />;
      case '2':
        return <Redirected showHrAdminView={isHrAdmin} />;
      case '3':
        return <Settings goToLanding={this.changeTab} />;
      default:
        return <Landing handleBegin={() => this.changeTab({ target: { value: '1' } })} />;
    }
  };

  changeTab = ({ target: { value } }) => {
    const { setTab } = this.props;
    setTab(value);
    this.setState({ activeTab: value });
  }

  render() {
    const { activeTab } = this.state;
    const { handleLogOut, user: { isCustomerHrAdmin: isHrAdmin } } = this.props;
    const returnableEl = this.mapReturnableView(activeTab, isHrAdmin);
    return (
      <Page>
        <TopBar>
          <InlineFlexContainer style={{ width: '100vw', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
            <NoStyleButton style={{ marginLeft: '2.0rem', marginBottom: '1.5rem' }} onClick={this.changeTab}>
              <SecondaryHeader>Työurahuolto</SecondaryHeader>
            </NoStyleButton>
            <InlineFlexContainer>
              <RectangleButton value="1" active={activeTab === '1'} onClick={this.changeTab}>Ohjaa palveluun</RectangleButton>
              <RectangleButton value="2" active={activeTab === '2'} onClick={this.changeTab}>Lähetetyt työntekijät</RectangleButton>
              {/*
                <RectangleButton value="3" active={activeTab === '3'} onClick={this.changeTab}>
                Asetukset</RectangleButton>
              */}
              <RectangleButton onClick={handleLogOut}>Kirjaudu ulos</RectangleButton>
            </InlineFlexContainer>
          </InlineFlexContainer>
        </TopBar>
        {returnableEl}
      </Page>
    );
  }
}

Employer.propTypes = {
  handleLogOut: propTypes.func.isRequired,
  setTab: propTypes.func.isRequired,
  user: propTypes.shape({}).isRequired,
};

export default withAuthContext(Employer);
