/* global alert, document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PageFlexContainer, Page, BasicFlexContainer } from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import BasicInfo from './BasicInfo';
import PersonalPreferences from './PersonalPreferences';
import { CheckboxInput } from '../../styled/inputs';
import { PrimaryHeader, CheckboxLabel } from '../../styled/headers';
import BaronaFooter from '../../BaronaFooter/BaronaFooter';
import validateForm from '../../../service/validation/formValidator';
import validationRules from './validationRules';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  birthdate: '',
  phone: '',
  email: '',
  location: '',
  inOwnWords: '',
  iWantHelp: false,
  iAmDrifting: false,
  noMotivation: false,
  notInterested: false,
  differentViewsOnGoals: false,
  tooDifficultJob: false,
  discontentWithEmployer: false,
  unSupportiveEmployer: false,
  civilLifeReasons: false,
  errors: null,
  consent: false,
};

class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    const { tuhcase } = props;
    this.state = Object.assign({}, INITIAL_STATE, tuhcase);
  }

  handleChange = (e) => {
    const { target: { type, checked } } = e;
    let { target: { name: newName, value: newValue } } = e;
    this.setState(() => {
      if (type === 'checkbox' && newName === 'preference') {
        newName = newValue;
        newValue = checked;
      }
      return { [newName]: newValue };
    });
  }

  handleSave = async () => {
    const { feedback: { tuhcaseId, id }, handleSave } = this.props;
    const data = Object.assign({}, this.state, { tuhcaseId });
    const errors = validateForm(data, validationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return this.setState({ errors });
    }
    if (data.errors) {
      delete data.errors;
    }
    if (data.birthdate) {
      data.birthdate = moment(data.birthdate, 'DD.MM.YYYY');
    }
    try {
      data.consent = null;
      data.errors = null;
      Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);
      const result = await handleSave(id, data);
      // eslint-disable-next-line no-console
      console.log('save successful:', result);
      return this.setState({ ...INITIAL_STATE });
    } catch (e) {
      // eslint-disable-next-line no-console
      return console.error('err', e);
    }
  };

  toggleConsent = () => this.setState(prevState => ({ consent: !prevState.consent }));

  render() {
    const { consent } = this.state;
    return (
      <Page>
        <PageFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <PrimaryHeader>
            Tarkista että tietosi ovat oikein
          </PrimaryHeader>
          <div
            style={{
            }}
          >
            <strong style={{ verticalAlign: 'sub' }}>*</strong>
            {' '}
            - pakollinen tieto
          </div>
          <BasicInfo
            handleChange={this.handleChange}
            stateValue={this.state}
          />
          <PrimaryHeader>
            Mitkä tilanteista kuvaavat sinua?
          </PrimaryHeader>
          <PersonalPreferences stateValue={this.state} handleChange={this.handleChange} />

          <BasicFlexContainer
            direction="column"
            style={{
              flexDirection: 'column', justifyContent: 'space-between', padding: '1rem 0rem', maxWidth: '60vw', alignItems: 'center',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              Lähettämällä lomakkeen, annan suostumuksen tietojen käsittelyyn
              Barona Työkykypalvelut Oy:lle. Antamani tiedot tallennetaan
              Baronan palveluun ja ne tulevat vain Barona Työkykypalvelut Oy:n
              tietoon ja niitä käsitellään täysin luottamuksellisesti
            </div>
            <p>
              <a
                href="https://insights.barona.fi/tietosuojaseloste-tyokykypalvelut-0"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                Lue tästä lisää henkilötietojen käsittelystä
              </a>
            </p>
            <p>
              <CheckboxInput name="consent" checked={consent} onChange={this.toggleConsent} />
              <CheckboxLabel style={{ textDecoration: 'underline' }}>Hyväksyn tietojen käsittelyn</CheckboxLabel>
            </p>
            <div style={{ margin: '50px 0' }}>
              <PillButton style={{ color: '#379CF0' }} onClick={() => (consent ? this.handleSave() : alert('Hyväksy ensin tietojen käsittely.'))}>
                Hyväksy ja Lähetä
              </PillButton>
            </div>
          </BasicFlexContainer>

        </PageFlexContainer>
        <BaronaFooter />
      </Page>
    );
  }
}

EmployeeForm.propTypes = {
  match: PropTypes.shape({}).isRequired,
  feedback: PropTypes.shape({
    tuhcaseId: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  handleSave: PropTypes.func.isRequired,
  tuhcase: PropTypes.shape({}).isRequired,
};

export default EmployeeForm;
