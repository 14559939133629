import React from 'react';
import PropTypes from 'prop-types';
import { Page, BasicFlexContainer, LandingFlexContainer } from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import { PrimaryHeader } from '../../styled/headers';
import BaronaFooter from '../../BaronaFooter/BaronaFooter';
import Welcome from '../../../assets/BackgroundRocket.jpg';

const Landing = ({ handleBegin }) => (
  <Page>
    <LandingFlexContainer direction="row">
      <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
        <PrimaryHeader style={{ marginBottom: 0 }}>
          Tervetuloa Työurahuoltoon!
        </PrimaryHeader>
        <BasicFlexContainer
          direction="column"
          height="10rem"
          width="35rem"
          style={{
            flexDirection: 'column',
            padding: '2rem 0rem',
            color: '#FFFFFF',
          }}
        >
          <p>
            Palvelumme tarkoitus on auttaa sinua työuraan liittyvissä tilanteissa tai haasteissa.
            Saat palvelussamme avuksesi henkilökohtaisen työuravalmentajan,
            joka paneutuu tilanteeseesi ja auttaa sinua ratkaisun löytämisessä.
          </p>
          <p>
            Jotta pääsemme alkuun ja voimme olla sinuun yhteydessä,
            tarvitsemme lisätietoja seuraavalla lomakkeella.
            Tiedot tallennetaan järjestelmäämme ja niitä käsitellään luottamuksellisesti.
            Työnantaja ei näe kirjaamisia tietoja.
          </p>
        </BasicFlexContainer>
        <PillButton onClick={handleBegin} style={{ color: '#379CF0', width: '15rem' }}>Jatka lomakkeeseen</PillButton>
      </BasicFlexContainer>
    </LandingFlexContainer>
  </Page>
);

Landing.propTypes = {
  handleBegin: PropTypes.func.isRequired,
};

export default Landing;
