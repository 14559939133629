/* global document, alert  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PageFlexContainer, Page } from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import { PrimaryHeader, CheckboxLabel } from '../../styled/headers';
import BasicInfo from './BasicInfo';
import EmployerFeelsLike from './EmployerFeelsLike';
import RelationsAndOwnWords from './RelationsAndOwnWords';
import { postCase } from '../../../service/transport/employer';
import { CheckboxInput } from '../../styled/inputs';
import validateForm from '../../../service/validation/formValidator';
import validationRules from './validationRules';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  birthdate: '',
  phone: '',
  email: '',
  location: '',
  roleRelation: '',
  inOwnWords: '',
  workLifeConcerns: false,
  workMotivationConcerns: false,
  lifeSituationConcerns: false,
  competenceConcerns: false,
  futureCompetenceConcerns: false,
  personalityConcerns: false,
  wantsNewJob: false,
  discontentWithEmployer: false,
  discontentWithCircumstances: false,
  errors: null,
  consent: false,
};

class EmployerForm extends Component {
  constructor() {
    super();
    this.state = Object.assign({}, INITIAL_STATE);
  }

  handleFormChange = (e) => {
    const { target: { type, checked } } = e;
    let { target: { name: newName, value: newValue } } = e;
    this.setState(() => {
      if (type === 'checkbox' && (newName === 'feelings' || newName === 'consent')) {
        newName = newValue;
        newValue = checked;
      }
      return { [newName]: newValue };
    });
  }

  handleSave = async () => {
    const data = Object.assign({}, this.state);
    const errors = validateForm(data, validationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return this.setState({ errors });
    }
    if (data.errors) {
      delete data.errors;
    }
    if (data.birthdate) {
      data.birthdate = moment(data.birthdate, 'DD.MM.YYYY');
    }
    try {
      data.consent = false;
      Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);
      const result = await postCase(data);
      // eslint-disable-next-line no-console
      console.log('save successful:', result);
      this.setState({ ...INITIAL_STATE });
      const { goToRedirectedTable } = this.props;
      return goToRedirectedTable();
    } catch (e) {
      // eslint-disable-next-line no-console
      return console.error('err', e);
    }
  }

  render() {
    const value = this.state;
    const { consent } = value;

    return (
      <Page style={{ backgroundColor: '#ffffff' }}>
        <PageFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <PrimaryHeader color="#393939">
            Täydennä työntekijän tiedot
            <br />
            ja kerro meille tilanteesta

          </PrimaryHeader>
          <div
            style={{ color: '#393939', marginTop: '1rem' }}
          >
            Lomakkeet ovat luottamuksellisia
            <br />
            ja tiedot näkee vain työurapalvelun ohjaaja.
          </div>
          <BasicInfo
            stateValue={value}
            handleChange={this.handleFormChange}
          />
          <EmployerFeelsLike stateValue={value} handleChange={this.handleFormChange} />

          <div style={{ maxWidth: '50rem', color: '#393939' }}>
            <RelationsAndOwnWords
              stateValue={value}
              handleChange={this.handleFormChange}
            />
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
                maxWidth: '70vh',
                color: '#393939',
              }}
            >
              Lähettämällä lomakkeen, annan suostumuksen tietojen käsittelyyn
              Barona Työkykypalvelut Oy:lle. Antamani tiedot tallennetaan
              Baronan palveluun ja ne tulevat vain Barona Työkykypalvelut Oy:n
              tietoon ja niitä käsitellään täysin luottamuksellisesti
              <p>
                <a
                  href="https://insights.barona.fi/tietosuojaseloste-tyokykypalvelut-0"
                  target="_blank"
                  style={{ color: 'black' }}
                  color="#393939"
                  rel="noopener noreferrer"
                >
                  Lue tästä lisää henkilötietojen käsittelystä
                </a>
              </p>
              <p>
                <CheckboxInput name="consent" value="consent" checked={consent} onChange={this.handleFormChange} style={{ color: '#393939' }} />
                <CheckboxLabel style={{ textDecoration: 'underline', color: '#393939' }}>Hyväksyn tietojen käsittelyn</CheckboxLabel>
              </p>

              <div style={{ margin: '30px 0 20px 0' }}>
                <PillButton
                  blue
                  style={{ width: '20rem' }}
                  onClick={() => (consent ? this.handleSave() : alert('Hyväksy ensin tietojen käyttö.'))}
                >
                  Lähetä työntekijä palveluun
                </PillButton>
              </div>
              <div>
                <PillButton style={{ border: 'solid 2px', marginBottom: '2rem', width: '20rem' }} onClick={this.handleSave}>Peruuta</PillButton>
              </div>
            </div>
          </div>
        </PageFlexContainer>
      </Page>
    );
  }
}

EmployerForm.propTypes = {
  goToRedirectedTable: PropTypes.func.isRequired,
};

export default EmployerForm;
