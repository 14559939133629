import React from 'react';
import image from '../../assets/kuvitus_1.svg';
import { Logo } from '../styled/containers';

const backgroundImage1 = () => (
  <Logo>
    <img src={image} alt="background_image" />
  </Logo>
);

export default backgroundImage1;
