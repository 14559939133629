/* eslint-disable */
import XLSX from 'xlsx';

export const generateTuhReport = (cases) => {
  const caseRows = cases.map(tuhcase => ({
    Lähettäjä: tuhcase.user.firstName + ' ' + tuhcase.user.lastName,
    Yritys: tuhcase.customerName,
    Ohjattu_työntekijä: tuhcase.firstName + ' ' + tuhcase.lastName,
    Puhelin: tuhcase.phone,
    Sähköposti: tuhcase.email,
    Paikkakunta: tuhcase.location,
    Ohjautumisen_syyt_tilaaja: tuhcase.commissiondetails.employerReasons.join('\n'),
    Tilaajan_omin_sanoin: tuhcase.commissiondetails.employerOwnWords,
    Avun_tarve_työntekijä: tuhcase.commissiondetails.employeeReasons.join('\n'),
    Työntekijän_omin_sanoin: tuhcase.commissiondetails.employeeOwnWords,
    Lomake_lähetetty: tuhcase.createdAt,
    Lomake_avattu: tuhcase.employeefeedback ? tuhcase.employeefeedback.createdAt : 'Ei avattu',
    Lomake_tallennettu: tuhcase.employeefeedback && tuhcase.employeefeedback.updatedAt && !tuhcase.tokenHash ? tuhcase.employeefeedback.updatedAt : 'Ei tallennettu',
  }))
  const hoursWS = XLSX.utils.json_to_sheet(caseRows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, hoursWS, 'Tuh-cases');
  XLSX.writeFile(wb, 'Työurahuoltoraportti.xlsx');
};
