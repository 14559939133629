import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer } from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import { PrimaryHeader } from '../../styled/headers';

const Settings = ({ goToLanding }) => (
  <BasicFlexContainer direction="column" style={{ flexDirection: 'column', padding: '5rem 0rem 0 9rem', width: '30rem' }}>
    <PrimaryHeader>
      Asetukset sivu
    </PrimaryHeader>
    <BasicFlexContainer
      direction="column"
      style={{ flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <span>
        Täällä asentajat asentaa asetuksia
      </span>
    </BasicFlexContainer>
    <div style={{ paddingTop: '2rem' }}>
      <PillButton onClick={goToLanding}>Mene etusivulle</PillButton>
    </div>
  </BasicFlexContainer>
);

Settings.propTypes = {
  goToLanding: PropTypes.func.isRequired,
};

export default Settings;
