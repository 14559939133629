/* global document alert window */
import React, { Component } from 'react';
import { BasicFlexContainer } from '../../styled/containers';
import { PillButton } from '../../styled/buttons';
import { TableInputLabel, TableTextInput } from '../../styled/inputs';
import validateForm from '../../../service/validation/formValidator';
import validationRules from './validationRules';
import {
  getCustomers,
  saveKeycode,
  getKeycodes,
  deleteKeycode,
} from '../../../service/transport/admin';
import Table from '../../Table/Table';
import Dropdownmenu from '../../DropdownMenu/DropdownMenu';

const columns = handleDelete => ([
  {
    id: 'customer',
    Header: 'Yritys',
    accessor: ({ customer }) => (customer && customer.name) || '',
  },
  {
    id: 'contactMail',
    Header: 'Sähköposti',
    accessor: 'contactMail',
  },
  {
    id: 'keycode',
    Header: 'Avainkoodi',
    accessor: 'keycode',
  },
  {
    id: 'hr-admin',
    Header: 'Hr Admin koodi',
    accessor: '',
    Cell: ({ row }) => (<span>{row.original.isHrAdminRole ? 'Kyllä' : 'Ei'}</span>),
  },
  {
    id: 'deleteKC',
    Header: '',
    accessor: '',
    Cell: ({ row }) => (
      <div>
        <PillButton red onClick={() => window.confirm('Haluatko poistaa avainkoodin?') && handleDelete(row.original.id)}>Poista</PillButton>
      </div>
    ),
  }]);

const mapCustomersToDropdown = customers => customers.map(
  ({ name, customerId }) => ({ label: name, value: customerId }),
);

class Keycodes extends Component {
  constructor() {
    super();
    this.state = {
      customers: [],
      contactMail: '',
      savedKeys: [],
      customer: null,
      errors: null,
      hrAdminMail: '',
    };
  }

  componentDidMount() {
    this.findCustomersAndRelatedKeycodes();
  }

  findCustomersAndRelatedKeycodes = () => getCustomers().then(async (customers) => {
    const keycodes = await getKeycodes();
    const savedKeys = keycodes.map((k) => {
      const customer = customers.find(c => c.customerId === k.customerId);
      return ({ ...k, customer });
    });
    this.setState({
      customers: mapCustomersToDropdown(customers),
      savedKeys,
      customer: null,
      contactMail: '',
      hrAdminMail: '',
      errors: null,
    });
  });

  handleChange = (e) => {
    const name = e.target ? e.target.name : 'customer';
    const value = e.target ? e.target.value : e;
    this.setState(
      {
        [name]: value,
      },
    );
  }

  handleSave = () => {
    const { contactMail, customer, hrAdminMail } = this.state;
    const fields = { contactMail, customer, hrAdminMail };
    const errors = validateForm(fields, validationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return this.setState({ errors });
    }
    if (!hrAdminMail) delete fields.hrAdminMail;
    return saveKeycode(fields)
      .then(async () => {
        await this.findCustomersAndRelatedKeycodes();
        const el = document.querySelector('#savedKeys');
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alert('Avainkoodia ei voitu luoda. Yritä hetken päästä uudestaan.'
          + ' Jos ongelma jatkuu, ota yhteyttä Työurahuollon ylläpitoon.');
      });
  };

  handleDelete = async (id) => {
    const { deleted } = await deleteKeycode(id);
    if (deleted === 1) this.findCustomersAndRelatedKeycodes();
  }

  render() {
    const {
      customers, contactMail, hrAdminMail, customer, savedKeys, errors,
    } = this.state;
    return (
      <BasicFlexContainer
        direction="column"
        style={{
          flexDirection: 'column',
          padding: '8rem 10rem 8rem 10rem',
        }}
      >
        <BasicFlexContainer style={{
          margin: '1rem',
          padding: '1.5rem',
          background: '#FFFFFF',
          color: 'black',
          flexWrap: 'wrap',
        }}
        >
          <Dropdownmenu
            style={{
              width: '13.4rem',
              fontSize: '14px',
              padding: '0 1.5rem 0.2rem 0',
            }}
            name="Yritys"
            options={customers}
            value={customer}
            onChange={this.handleChange}
            error={errors && errors.has('customer')}
          />
          <BasicFlexContainer direction="column" style={{ flexDirection: 'column', padding: '0 1.5rem 0.2rem 0' }}>
            <TableInputLabel error={errors && errors.has('contactMail')}>Sähköposti</TableInputLabel>
            <TableTextInput
              name="contactMail"
              onChange={this.handleChange}
              value={contactMail}
            />
          </BasicFlexContainer>
          <BasicFlexContainer direction="column" style={{ flexDirection: 'column', padding: '0 1.5rem 0.2rem 0' }}>
            <TableInputLabel error={errors && errors.has('hrAdminMail')}>Hr Admin Sähköposti</TableInputLabel>
            <TableTextInput
              name="hrAdminMail"
              onChange={this.handleChange}
              value={hrAdminMail}
            />
          </BasicFlexContainer>
          <div style={{ flexDirection: 'column', padding: '1.3rem 1.5rem 1rem 0' }}>
            <PillButton blue onClick={this.handleSave}>Lähetä avainkoodi</PillButton>
          </div>
        </BasicFlexContainer>
        <Table
          data={savedKeys}
          click={() => {}}
          columns={columns(this.handleDelete)}
        />
      </BasicFlexContainer>
    );
  }
}

export default Keycodes;
