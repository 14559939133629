/* global fetch */

import AuthService from '../authService/authService';

const Auth = new AuthService(process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002');

const backend = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';
const API_PREFIX = '/api/v1';

export const getCases = async () => Auth.fetch(`${backend}${API_PREFIX}/admin/cases`, {
  method: 'GET',
});

export const deleteCase = async id => Auth.fetch(`${backend}${API_PREFIX}/admin/case/${id}`, {
  method: 'DELETE',
});

export const getCustomers = async () => Auth.fetch(`${backend}${API_PREFIX}/admin/customers`, {
  method: 'GET',
});

export const getKeycodes = async () => Auth.fetch(`${backend}${API_PREFIX}/admin/keycodes`, {
  method: 'GET',
});

export const getUsers = async () => Auth.fetch(`${backend}${API_PREFIX}/admin/users`, {
  method: 'GET',
});

export const saveUser = async data => Auth.fetch(`${backend}${API_PREFIX}/admin/users`, {
  body: JSON.stringify(data),
  method: 'POST',
});

export const updateUser = async data => Auth.fetch(`${backend}${API_PREFIX}/admin/users/${data.id}`, {
  body: JSON.stringify(data),
  method: 'PUT',
});

export const deactivateUser = async id => Auth.fetch(`${backend}${API_PREFIX}/admin/users/${id}`, {
  method: 'DELETE',
});

export const saveKeycode = async data => Auth.fetch(`${backend}${API_PREFIX}/admin/keycodes`, {
  body: JSON.stringify(data),
  method: 'POST',
});

export const deleteKeycode = async id => Auth.fetch(`${backend}${API_PREFIX}/admin/keycodes/${id}`, {
  method: 'DELETE',
});
