import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthService from './authService';
import AuthContext from '../../contexts/AuthContext';

export default function withAuth(AuthComponent) {
  const Auth = new AuthService(process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002');
  return class AuthWrapped extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      history: PropTypes.shape({
        replace: PropTypes.func,
      }).isRequired,
    }

    constructor() {
      super();
      this.auth = Auth;
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
      const { history } = this.props;
      if (!Auth.loggedIn()) {
        history.replace('/login');
      }
    }

    logOut = () => {
      const { history } = this.props;
      Auth.logout();
      history.replace('/login');
    }

    setTab = idx => this.auth.setActiveTab(idx)

    getTab = () => this.auth.getActiveTab()

    render() {
      if (!Auth.loggedIn()) return null;
      Auth.setActiveTab(0);
      const user = Auth.getProfile();
      const { history } = this.props;
      const contextValue = {
        user, history, handleLogOut: this.logOut, setTab: this.setTab, getTab: this.getTab,
      };

      if (user) {
        return (
          <AuthContext.Provider value={contextValue}>
            <AuthComponent user={user} />
          </AuthContext.Provider>
        );
      }
      return (
        <div>Työurahuolto latautuu... Odota hetki</div>
      );
    }
  };
}
