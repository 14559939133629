/* global alert fetch window, document */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  BasicFlexContainer, InlineFlexContainer, Page, TopBar,
} from '../styled/containers';
import { PrimaryHeader } from '../styled/headers';
import FlexForm from '../styled/FlexForm';
import { InputLabel, TextInput } from '../styled/inputs';
import { NoStyleButton, PillButton } from '../styled/buttons';
import BaronaFooter from '../BaronaFooter/BaronaFooter';
import logo from '../../assets/tuh_logo.png';

class PasswordReset extends Component {
  state = {
    password: '',
    passwordSecond: '',
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) this.sendNewPassword();
  }

  handleSave = (e) => {
    e.preventDefault();
    const { sendNewPW } = this.props;
    const { password, passwordSecond } = this.state;
    if (password !== passwordSecond) {
      alert('Salasanat eivät täsmää!');
      return;
    }

    if (!this.validatePassword(password)) {
      alert('Salasanan vähimmäispituus on 12 merkkiä, ja sen tulee sisältää pieniä ja isoja kirjaimia, numeroita sekä erikoismerkkejä.');
      return;
    }

    sendNewPW(password);
    this.setState({
      password: '',
      passwordSecond: '',
    });
  }

  validatePassword = (password) => {
    let match;

    // length
    if (password.length < 12) {
      return false;
    }

    // lower
    match = password.match(/[a-zäöå]{1,}/g);
    if (!match) {
      return false;
    }

    // UPPER
    match = password.match(/[A-ZÄÖÄÅ]{1,}/g);
    if (!match) {
      return false;
    }

    // num
    match = password.match(/[0-9]{1,}/g);
    if (!match) {
      return false;
    }

    // special
    match = password.match(/[^0-9a-zA-ZäöåÄÖÅ]{1,}/g);
    if (!match) {
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  render() {
    return (
      <Page>
        <TopBar>
          <InlineFlexContainer style={{ width: '100vw', justifyContent: 'left', flexWrap: 'nowrap' }}>
            <div style={{ margin: '.5rem 0rem .5rem 1rem' }}>
              <NoStyleButton type="button" onClick={() => window.location.replace('/')}>
                <img src={logo} alt="Työurahuolto" />
              </NoStyleButton>
            </div>
          </InlineFlexContainer>
        </TopBar>
        <BasicFlexContainer
          direction="column"
          style={{
            flexDirection: 'column',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PrimaryHeader>Valitse uusi salasana</PrimaryHeader>
          <p>
            <i>
              Salasanan vähimmäispituus on 12 merkkiä. Salasanan tulee sisältää isoja ja
              pieniä kirjaimia, numeroita sekä erikoismerkkejä.
            </i>
          </p>
          <FlexForm onSubmit={this.handleSave}>
            <InputLabel htmlFor="username">Salasana *</InputLabel>
            <TextInput
              name="password"
              type="password"
              onChange={this.handleChange}
            />
            <InputLabel htmlFor="password">Salasana uudelleen *</InputLabel>
            <TextInput
              name="passwordSecond"
              type="password"
              onChange={this.handleChange}
            />
            <BasicFlexContainer style={{ paddingTop: '2rem' }}>
              <PillButton style={{ width: '100%' }}>Vaihda Salasana</PillButton>
            </BasicFlexContainer>
          </FlexForm>
        </BasicFlexContainer>
        <BaronaFooter position="fixed" />
      </Page>
    );
  }
}

PasswordReset.propTypes = {
  sendNewPW: PropTypes.func.isRequired,
};

export default PasswordReset;
