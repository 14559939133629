/* global document, window, alert */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PillButton } from '../../styled/buttons';
import { InlineFlexContainer, BasicFlexContainer } from '../../styled/containers';
import { saveUser, getCustomers } from '../../../service/transport/admin';
import validateForm from '../../../service/validation/formValidator';
import { addUserValidationRules } from './validationRules';
import FlexForm from '../../styled/FlexForm';
import { TableTextInput, TableInputLabel } from '../../styled/inputs';
import { SecondaryHeader, ModalFormHeader } from '../../styled/headers';
import Dropdownmenu from '../../DropdownMenu/DropdownMenu';

const roles = [
  { label: 'Esimies', value: '' },
  { label: 'Barona admin', value: 'isAdmin' },
  { label: 'Hr admin', value: 'isCustomerHrAdmin' },
];

const AddUserModal = ({ closeModal }) => {
  const [form, setValues] = useState({
    customerId: null,
    isAdmin: false,
    isCustomerHrAdmin: false,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    department: '',
    errors: null,
  });

  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async () => {
    const res = await getCustomers();
    setCustomers(res.map(c => ({ label: c.name, value: c.customerId })));
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    const data = { ...form };
    if (data.isAdmin) {
      data.customerId = 100;
    }
    const errors = validateForm(data, addUserValidationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return setValues({
        ...form,
        errors,
      });
    }
    if (data.errors) {
      setValues({
        ...form,
        errors: null,
      });
    }
    if (form.isAdmin && !window.confirm('Olet antamassa käyttäjälle Barona admin -roolin, jolla on näkymä kaikkien yritysten kaikkiin tietoihin. Haluatko varmasti jatkaa?')) return true;
    Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);
    try {
      await saveUser(data);
      return closeModal();
    } catch (e) {
      return alert(e);
    }
  };

  const updateField = (e) => {
    if (!e.target && e.label) {
      let updatedValues;
      if (typeof e.value === 'number') {
        updatedValues = Object.assign(
          {},
          form,
          { customerId: e.value },
        );
      } else {
        updatedValues = Object.assign(
          {},
          form,
          { isAdmin: false, isCustomerHrAdmin: false },
          e.value ? { [e.value]: true } : {},
        );
      }
      return setValues({
        ...updatedValues,
      });
    }
    return setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const customer = customers.find(c => c.value === form.customerId);

  return (
    <FlexForm onSubmit={handleSave}>
      <BasicFlexContainer direction="column" style={{ flexDirection: 'column', width: '28rem' }}>
        <ModalFormHeader color="#379CF0">
          Lisää käyttäjä
        </ModalFormHeader>
        <SecondaryHeader color="#353535" style={{ paddingBottom: '3rem' }}>
          Luo uusi käyttäjä täyttämällä tiedot. Käyttäjätunnus on välittömästi käytössä.
        </SecondaryHeader>
        <div
          style={{ color: '#353535', fontSize: '14px', paddingBottom: '1rem' }}
        >
          <strong style={{ verticalAlign: 'sub' }}>*</strong>
          {' '}
          - pakollinen tieto
        </div>
      </BasicFlexContainer>
      <InlineFlexContainer>
        <Dropdownmenu
          style={{
            width: '13.4rem',
            fontSize: '14px',
            padding: '0 1.5rem 0.2rem 0',
            color: '#353535',
          }}
          name="Rooli"
          options={roles}
          onChange={updateField}
        />
        <Dropdownmenu
          isDisabled={form.isAdmin}
          style={{
            width: '13.4rem',
            fontSize: '14px',
            padding: '0 0 0.2rem 0',
            color: '#353535',
          }}
          name="Yritys"
          value={customer}
          options={customers}
          onChange={updateField}
          error={form.errors && form.errors.has('customerId')}
        />
      </InlineFlexContainer>

      <InlineFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column', marginRight: '1.5rem' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('firstName')}>Etunimi *</TableInputLabel>
          <TableTextInput
            name="firstName"
            type="text"
            value={form.firstName}
            onChange={updateField}
          />
        </BasicFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('lastName')}>Sukunimi *</TableInputLabel>
          <TableTextInput
            name="lastName"
            type="text"
            value={form.lastName}
            onChange={updateField}
          />
        </BasicFlexContainer>
      </InlineFlexContainer>
      <InlineFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column', marginRight: '1.5rem' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('email')}>Sähköposti * (tunnus)</TableInputLabel>
          <TableTextInput
            name="email"
            type="text"
            value={form.email}
            onChange={updateField}
          />
        </BasicFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <TableInputLabel color="#353535">Puhelinnumero</TableInputLabel>
          <TableTextInput
            name="phone"
            type="text"
            value={form.phone}
            onChange={updateField}
          />
        </BasicFlexContainer>
      </InlineFlexContainer>
      <InlineFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column', marginRight: '1.5rem' }}>
          <TableInputLabel color="#353535">Osasto (valinnainen)</TableInputLabel>
          <TableTextInput
            name="department"
            type="text"
            value={form.department}
            onChange={updateField}
          />
        </BasicFlexContainer>
        <BasicFlexContainer direction="column" style={{ flexDirection: 'column' }}>
          <TableInputLabel color="#353535" error={form.errors && form.errors.has('password')}>Salasana *</TableInputLabel>
          <TableTextInput
            name="password"
            type="password"
            value={form.password}
            onChange={updateField}
          />
        </BasicFlexContainer>
      </InlineFlexContainer>
      <InlineFlexContainer style={{ paddingTop: '3rem' }}>
        <PillButton style={{ width: '13.7rem', marginRight: '1.5rem' }} red onClick={closeModal}>Peruuta</PillButton>
        <PillButton style={{ width: '13.7rem' }} blue>Tallenna</PillButton>
      </InlineFlexContainer>
    </FlexForm>
  );
};

AddUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AddUserModal;
