import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startEmployeePath, updateFeedback, checkToken } from '../../service/transport/employee';
import Landing from '../../components/employee/landing/Landing';
import EmployeeForm from '../../components/employee/form/EmployeeForm';
import Finish from '../../components/employee/finish/Finish';
import { LinkNotValid } from '../../components/styled/containers';
import testIfInternetExplorer from '../../service/authService/ieTester';

class Employee extends Component {
  state = {
    pageIndex: 0,
    tuhcaseId: null,
    tokenIsValid: true,
    tokenIsChecked: false,
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    testIfInternetExplorer();
  }

  componentDidMount() {
    const { match: { params: { token } } } = this.props;
    checkToken(token)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }
        throw new Error('Ei oikeutta!!!');
      })
      .then(res => this.setState({
        tuhcaseId: res.tuhcaseId,
        tokenIsValid: true,
        tokenIsChecked: true,
      }))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Token not found!');
        this.setState({ tokenIsValid: false, tokenIsChecked: true });
      });
  }

  componentWillUnmount() {
    this.setState({
      pageIndex: 0, tuhcaseId: null, tokenIsValid: false, tokenIsChecked: false,
    });
  }

  continue = () => {
    const { tuhcaseId } = this.state;
    const body = JSON.stringify({ tuhcaseId });
    return startEmployeePath(body)
      .then(({ feedback, tuhcase }) => {
        this.setState({ pageIndex: 1, feedback, tuhcase });
      });
  }

  mapPageIndexToPage = (index) => {
    if (index === 1) {
      const { feedback, tuhcase } = this.state;
      return <EmployeeForm feedback={feedback} tuhcase={tuhcase} handleSave={this.saveForm} />;
    } if (index === 2) {
      return <Finish />;
    }
    return <Landing handleBegin={this.continue} />;
  }

  saveForm = (id, data) => updateFeedback(id, data)
    .then((() => this.setState({ pageIndex: 2 })))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });

  render() {
    const { tokenIsValid, tokenIsChecked } = this.state;
    if (tokenIsChecked) {
      if (tokenIsValid) {
        const { pageIndex } = this.state;
        const pageToRender = this.mapPageIndexToPage(pageIndex);
        return pageToRender;
      }
      return (
        <LinkNotValid>Sivua ei löydy!</LinkNotValid>
      );
    }
    return null;
  }
}

Employee.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default Employee;
