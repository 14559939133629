import styled from 'styled-components';

export const TextInput = styled.input`
  width: 13.625rem;
  height: 3.0625rem;
  font-size: 1.25rem;
  padding-left: .5rem
`;

export const TableTextInput = styled.input`
  padding-left: .5rem;
  font-size: 14px;
  width: 12.6875rem;
  height: 2rem;
  margin-top: 0.6rem;
`;

export const InputLabel = styled.label`
  font-size: 18px;
  letter-spacing: 1.1px;
  margin-bottom: 5px;
  color: ${props => (props.error ? 'red' : (props.color || '#ffffff'))};
`;

export const TableInputLabel = styled.label`
  font-size: 14px;
  color: ${props => (props.error ? 'red' : (props.color || '#353535'))};
`;

export const InputLabelLargeGap = styled.label`
  font-size: 18px;
  letter-spacing: 1.1px;
  margin-bottom: 20px;
  margin-top: 10px;
  ${({ error }) => error && 'color: red;'}
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  transform: scale(2.0);
  margin: 10px 2.5rem 10px 5px;
`;
