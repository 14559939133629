export const addUserValidationRules = {
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  password: 'required',
  customerId: 'required',
};

export const editUserValidationRules = {
  firstName: 'required',
  lastName: 'required',
  email: 'required',
};
