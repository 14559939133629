/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { BasicFlexContainer } from '../../styled/containers';
import { PrimaryHeader, SecondaryHeader } from '../../styled/headers';
import { PillButton } from '../../styled/buttons';
import { TextInput, InputLabel } from '../../styled/inputs';
import FlexForm from '../../styled/FlexForm';
import validateForm from '../../../service/validation/formValidator';
import { keyValidationRules } from './validationRules';

class Keycode extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      errors: null,
    };
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { password } = this.state;
    const errors = validateForm({ password }, keyValidationRules);
    if (errors) {
      const firstErrorInField = Object.keys(errors.all())[0];
      const el = document.querySelector(`input[name="${firstErrorInField}"]`);
      if (el) el.focus();
      return this.setState({ errors });
    }
    const { handleBegin } = this.props;
    return handleBegin(password);
  }

  render() {
    const { password, errors } = this.state;
    return (
      <BasicFlexContainer
        direction="column"
        style={{
          flexDirection: 'column',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PrimaryHeader>Tervetuloa Työurahuoltoon.</PrimaryHeader>
        <SecondaryHeader>Kirjaudu sisään avainkoodillasi.</SecondaryHeader>
        <FlexForm onSubmit={this.handleFormSubmit}>
          <InputLabel error={errors}>Avainkoodi</InputLabel>
          <TextInput
            value={password}
            name="password"
            type="password"
            onChange={this.handleChange}
          />
          <BasicFlexContainer style={{ paddingTop: '2rem' }}>
            <PillButton style={{ width: '100%' }}>Kirjaudu</PillButton>
          </BasicFlexContainer>
        </FlexForm>
      </BasicFlexContainer>
    );
  }
}

Keycode.propTypes = {
  handleBegin: PropTypes.func.isRequired,
};

export default Keycode;
