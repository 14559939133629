import React from 'react';
import image from '../../assets/kiitos_kuvitus.svg';
import { Logo } from '../styled/containers';

const thankYouImage = () => (
  <Logo>
    <img src={image} alt="background_image" />
  </Logo>
);

export default thankYouImage;
