import React from 'react';
import PropTypes from 'prop-types';
import { BasicFlexContainer, FormRowContainer, InlineFlexContainer } from '../../styled/containers';
import { SecondaryHeader } from '../../styled/headers';
import { TextInput, InputLabel } from '../../styled/inputs';

const BasicInfo = ({ handleChange, stateValue }) => (
  <BasicFlexContainer
    direction="column"
    style={{
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1rem 0rem',
      maxWidth: '37rem',
    }}
  >
    <span style={{ marginBottom: '2rem' }}>
      Saimme perustietosi työnantajaltasi. Tarkasta, että tietosi ovat oikein.
    </span>
    <SecondaryHeader>Omat tiedot</SecondaryHeader>
    <InlineFlexContainer>
      <FormRowContainer direction="column" style={{ flexDirection: 'column' }}>
        <InputLabel
          error={stateValue.errors && stateValue.errors.has('firstName')}
          htmlFor="firstName"
        >
          Etunimi *
        </InputLabel>
        <TextInput
          name="firstName"
          value={stateValue.firstName}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel
          error={stateValue.errors && stateValue.errors.has('lastName')}
          htmlFor="lastName"
        >
          Sukunimi *
        </InputLabel>
        <TextInput
          name="lastName"
          value={stateValue.lastName}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel
          style={{ width: '15rem' }}
          error={stateValue.errors && stateValue.errors.has('birthdate')}
          htmlFor="birthdate"
        >
          {stateValue.errors && stateValue.errors.has('birthdate')
            ? <span>{stateValue.errors.get('birthdate')}</span>
            : <span>Syntymäaika *</span>
          }
        </InputLabel>
        <TextInput
          name="birthdate"
          value={stateValue.birthdate}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel
          error={stateValue.errors && stateValue.errors.has('email')}
          htmlFor="email"
        >
          Sähköposti *
        </InputLabel>
        <TextInput
          name="email"
          value={stateValue.email}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel
          error={stateValue.errors && stateValue.errors.has('phone')}
          htmlFor="phone"
        >
          Puhelinnumero *
        </InputLabel>
        <TextInput
          name="phone"
          value={stateValue.phone}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
      <FormRowContainer>
        <InputLabel>Kaupunki</InputLabel>
        <TextInput
          name="location"
          value={stateValue.location}
          type="text"
          onChange={handleChange}
        />
      </FormRowContainer>
    </InlineFlexContainer>
  </BasicFlexContainer>
);

BasicInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  stateValue: PropTypes.shape({}).isRequired,
};

export default BasicInfo;
